<template>
  <div>
    <!--------------------------------------->
    <!-- Log in Button                     -->
    <!--------------------------------------->
    <div v-if="user && !user.exists() && !$vuetify.breakpoint.xs">
      <v-btn
        @click="goToSignIn()"
        depressed
        color="primary"
        class="text-none tit rounded-lg"
      >
        Iniciar sessió
      </v-btn>
    </div>

    <user-button v-else></user-button>
  </div>

</template>

<script>
import UserButton from "./UserButton";

export default {
  components: {
    UserButton
  },
  computed: {
    token() {
      return this.$root.token
    },
    user() {
      return this.$root.currentUser
    }
  },
  methods: {
    goTo: function (row) {
      this.$router.push({path: `${row}`})
    },
    goToAuth() {
      this.$store.state.step = 1
      this.$store.commit('toggleAuth')
    },
    goToSignIn() {
      this.$router.push({path: '/login'})
    },
    async signOut() {
      await this.axios.delete('/logout', {
        headers: {
          'Authorization': this.token.value
        }
      });
      this.user.remove();
      this.token.remove();
      if (this.$route.path !== '/') {
        await this.$router.push({path: '/'})
      }
    }
  }
}
</script>

<style>

.custom-placeholer-color input::placeholder {
  color: white !important;
  font-weight: 600;
  font-family: Rubik, sans-serif !important;
  opacity: 1;
  letter-spacing: 0.5px;
  padding-left: 0px !important;
}

.magny .v-icon.v-icon {
  color: white !important;
  font-size: 18px !important;
  margin-left: 25px !important;
}

.custom-placeholer-color input {
  font-family: Rubik, sans-serif !important;
  opacity: 1;
  letter-spacing: 0.5px;
  color: white !important;
  border: none !important;
}

.custom-placeholer-color .v-input__slot {
  background-color: transparent !important;
  box-shadow: none !important;
}
</style>
