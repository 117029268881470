

<template>
  <v-dialog v-model="literaturaAlert"
            :fullscreen="$vuetify.breakpoint.smAndDown"
            max-width="500">
    <v-card max-width="500">
      <v-card-title class="font-rubik">⚠️ Atenció!</v-card-title>
      <v-divider></v-divider>
      <div class="pa-5">
          <span class="font-rubik">Acabes d'afegir LITERATURA {{ literaturaAlertMsg }} (optativa) que és diferent de LLENGUA {{
              literaturaAlertMsg
            }} (comuna). </span>
      </div>

      <div class="d-flex flex-column align-center py-5">
        <v-btn
          @click="swapCart(literaturaAlertMsg=='CASTELLANA' ? 29 : 30, literaturaAlertMsg=='CASTELLANA' ? 10 : 9)"
          color="primary" class="mb-3 font-rubik">Canviar i comprar LLENGUA {{ literaturaAlertMsg }}
        </v-btn>
        <v-btn
          @click="$emit('update:literaturaAlert', false)"
          class="font-rubik">Comprar LITERATURA {{ literaturaAlertMsg }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>

</template>

<script>
export default {
  name: "AlertaLiteratura",
  props: {
    literaturaAlert: Boolean,
    literaturaAlertMsg: String,
    books: Array
  },
  data() {
    return {}
  },
  methods: {
    swapCart(oldId, newId) {
      let list = this.$cookies.get('cart');
      delete list[oldId]
      this.$cookies.set('cart', list)
      this.$root.cart = Object.values(list).reduce((a, b) => a + b, 0);
      this.$emit("update:literaturaAlert", false)
      const book = this.books.find(b => b.book_id = newId)
      setTimeout(() => {
        this.$emit("addToCart", book)
      }, 200)
    },
  }
}
</script>

<style scoped>

</style>
