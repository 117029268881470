<template>
  <div :style=" ($vuetify.breakpoint.lgAndUp || selected) ? 'width: 100%' : ''"
       class="d-flex align-start"
  >
    <v-btn v-if="selected"
           @click="selected=false"
           style="max-width: 28px"
           :ripple="false"
           class="no-background-hover"
           aria-label="Treure buscador"
           icon>
      <v-icon size="33" color="#282828">{{mdiChevronLeft}}</v-icon>
    </v-btn>

    <v-autocomplete
      v-if="$vuetify.breakpoint.lgAndUp || selected || !appbar"
      :search-input.sync="search"
      :items="items"
      class="font-weight-medium"
      :class="appbar ? (selected ? 'autocomplete-search-full' : 'autocomplete-search-appbar') : 'autocomplete-search'"
      background-color="white"
      color="white"
      label="Busca assignatures, exàmens o temes"
      :menu-props="{
      maxHeight: selected ? '100%' : '400px',
      width: appbar ? '320px' : '100%',
      id: 'searchMenu',
      bootom: true
    }"
      no-data-text="No hem trobat resultats"
      hide-no-data
      :append-icon="mdiMagnify"
      hide-details
      style="width: 100%"
      no-filter
      single-line
      :height="appbar ? (selected ? '100%' : 36) : 52"
      flat
      solo
    >
      <template v-slot:progress>
        <v-progress-circular color="indigo" indeterminate></v-progress-circular>
      </template>
      <template v-slot:item="data">
        <template v-if="data.header">
          <span class="pl-2">{{ header }}</span>
        </template>
        <template v-if="data.item.name">
          <v-list-item-content
            @click="nextAssignatura(data.item)"
            class="d-flex"
          >
            <v-list-item-title class="d-flex align-center">
              <!--            <v-icon left color="#256EFF" small>{{ data.item.icon }}</v-icon>-->
              <span v-html="data.item.name"></span>
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <template v-else-if="data.item.topic_name">
          <v-list-item-content
            @click="goTo(`/selectivitat/${data.item.subject_name}/per-temes/${data.item.topic_path}`)"
            class="d-flex"
          >
            <v-list-item-title class="d-flex align-center">
              <!--            <v-icon left color="#256EFF" small>{{ data.item.icon }}</v-icon>-->
              <span
                v-html="data.item.topic_name"></span>
              <span class="ml-2 body-2 blue-grey--text" v-html="data.item.subject_name"></span>
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <template v-else-if="data.item.keyword_name">
          <v-list-item-content @click="goTo(`/cercador-selectivitat/${data.item.subject_id}/${data.item.keyword_name}`)"
                               class="d-flex"
          >
            <v-list-item-title class="d-flex align-center">
              <!--            <v-icon left color="#256EFF" small>{{ data.item.icon }}</v-icon>-->
              <span
                v-html="data.item.keyword_name.charAt(0).toUpperCase() + data.item.keyword_name.slice(1).toLowerCase()"></span>
              <span class="ml-2 body-2 blue-grey--text" v-html="data.item.subject_name"></span>
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <template v-else-if="data.item.examen_per_anys">
          <v-list-item-content class="d-flex"
                               @click="goTo(`/selectivitat/${data.item.subject_name}/examens/${data.item.year}/${data.item.month}`)"
          >
            <v-list-item-title>
              <!--            <v-icon left color="#256EFF" small>{{ data.item.icon }}</v-icon>-->
              <span>Examen {{ data.item.subject_name }} {{ data.item.year }} {{ data.item.month }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
    <v-btn v-if="$vuetify.breakpoint.mdAndDown && appbar"
           @click="selected=true"
           style="max-width: 48px"
           aria-label="Buscar"
           :ripple="false"
           class="no-background-hover"
           icon>
      <v-icon size="27" color="#282828">{{mdiMagnify}}</v-icon>
    </v-btn>
  </div>
</template>

<script>

import {mdiChevronLeft} from '/src/assets/mdi.json'
import {mdiMagnify} from '/src/assets/mdi.json'

export default {
  components: {},
  props: {
    appbar: {
      default: false
    },
    onlySearch: {
      type: Boolean
    }
  },
  data() {
    return {
      mdiChevronLeft: mdiChevronLeft,
      mdiMagnify: mdiMagnify,
      selected: false,
      loading: false,
      items: [
        {header: 'Assignatures'},
        {
          "subject_id": "1",
          "name": "Anglès",
          "subject_name": "Anglès",
          "subject_path": "angles"
        },
        {
          "subject_id": "2",
          "name": "Història",
          "subject_name": "Història",
          "subject_path": "historia"
        },
        {
          "subject_id": "3",
          "name": "Llengua castellana",
          "subject_name": "Llengua castellana",
          "subject_path": "llengua-castellana"
        },
        {
          "subject_id": "4",
          "subject_name": "Llengua catalana",
          "name": "Llengua catalana",
          "subject_path": "llengua-catalana"
        },
        {header: 'Temes'},
        {
          "topic_id": 79,
          "topic_name": "El franquisme",
          "topic_path": "el-franquisme/4404",
          "subject_name": "Història",
          "subject_path": "historia",
        },
        {
          "topic_id": 38,
          "topic_name": "Redox, piles i electròlisi",
          "topic_path": "oxidacio-reduccio/4525",
          "subject_name": "Química",
          "subject_path": "quimica",
        },
        {
          "topic_id": 15,
          "topic_name": "Geometria de l'espai",
          "topic_path": "geometria-de-l-espai/1419",
          "subject_name": "Matemàtiques",
          "subject_path": "matematiques",
        },
        {
          "topic_id": 33,
          "topic_name": "La guerra civil",
          "topic_path": "la-guerra-civil/4406",
          "subject_name": "Història",
          "subject_path": "historia",
        },
      ],
      queryTerm: '',
      model: null
    }
  },
  methods: {
    nextAssignatura(item) {
      this.goTo(`/selectivitat/${item.subject_name}`)
    },
    async goTo(path) {
      await this.$router.push({path: path})
    },
    clearSearch() {
      this.searchMode = false
    },
    async fetchSearch() {
      if (this.queryTerm && this.queryTerm.length > 1) {
        this.loading = true;
        const path = `/search2/${this.queryTerm}`;
        const {data} = await this.axios.get(path);
        this.items = []
        if (data.assignatures && data.assignatures.length > 0) {
          this.items.push({header: 'Assignatures'});
          this.items.push(...data.assignatures);
        }
        if (data.topics && data.topics.length > 0) {
          this.items.push({header: 'Temes'});
          this.items.push(...data.topics);
        }
        if (data.anys && data.anys.length > 0) {
          this.items.push({header: 'Exàmens'});
          this.items.push(...data.anys);
        }
        if (data.tags && data.tags.length > 0) {
          this.items.push({header: 'Problemes'});
          this.items.push(...data.tags);
        }

        this.loading = false;
      }
    }
  },
  computed: {
    search: {
      get() {
        return this.queryTerm
      },
      set(searchInput) {
        if (searchInput)
          if (typeof searchInput == 'string') {
            this.queryTerm = searchInput
            this.fetchSearch()
          } else {
            if (searchInput.name) this.queryTerm = searchInput.name;
            else if (searchInput.topic_name) this.queryTerm = searchInput.topic_name;
            else if (searchInput.keyword_name) this.queryTerm = searchInput.keyword_name;
            else if (searchInput.any) this.queryTerm = searchInput.any;
          }
      }
    }
  },
  watch: {
    '$route.path'() {
      this.queryTerm = ''
      this.items = [];
    },
    selected(val) {
      this.$emit('update:onlySearch', val)
    }
  }
}
</script>

<style>
.v-autocomplete__content {
  margin-top: 2px;
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  min-height: 70px;
  background-color: white;
  /*box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;*/
  /*border: thin solid #edeeef*/
}
</style>

<style>
.v-menu__content >>> .v-list-item {
  min-height: 20px !important;
}

.autocomplete-search-full {
  max-height: 36px !important;
  min-height: 36px !important;
  width: 100%;
  max-width: 100% !important;
  flex: 1;
}

.autocomplete-search-appbar {
  max-height: 36px !important;
  min-height: 36px !important;
  max-width: 320px !important;
}

.autocomplete-search-appbar .v-label {
  font-size: 14px !important;
  padding-left: 6px;
}

.autocomplete-search-appbar input {
  border: thin solid #D1D5DB;
  min-height: 34px !important;
  border-radius: 6px 0 0 6px !important;
  padding-left: 12px !important;
  font-size: 14px !important;
}

.autocomplete-search-appbar .v-input {
  max-height: 44px !important;
  min-height: 100%;
}

.autocomplete-search-appbar .v-select__slot {
  height: 34px !important;
  min-height: 34px !important;
  padding-left: 0px;
}

.autocomplete-search-appbar .v-label {
  padding-left: 12px;
}

.autocomplete-search-appbar .v-input__control {
  height: 34px !important;
  min-height: 34px !important;
}

.autocomplete-search-appbar .v-input__slot {
  height: 34px !important;
  min-height: 34px !important;
  padding: 0 !important;
}

.autocomplete-search-appbar .v-input__append-inner {
  height: 34px !important;
  background-color: #256EFF;
  width: 40px;
  border-radius: 0 6px 6px 0;
  padding-top: 5px;
  padding-right: 4px;
}


.autocomplete-search {
  border-radius: 10px !important;
  max-width: 700px !important;
}

.autocomplete-search .v-select__slot {
  height: 52px !important;
}

.autocomplete-search .v-input__append-inner {
  height: 52px !important;
  width: 48px;
  background-color: #FFA201;
  border-radius: 0 10px 10px 0;
  padding-top: 14px;
  padding-right: 3px;
}

.v-menu__content >>> .v-list-item__content {
  padding: 2px !important;
}


.v-input__icon--append .v-icon {
  -webkit-transform: none !important;
  transform: none !important;
  font-size: 16px !important;
  color: white !important;
  height: 100%;
}

.v-input__icon--append .mdi-magnify {
  font-size: 24px !important;
}

.autocomplete-search .v-input__slot {
  padding: 0 0 0 16px !important;
}


.v-text-field--outline.v-input--has-state > .v-input__control > .v-input__slot,
.v-text-field--outline.v-input--is-focused > .v-input__control > .v-input__slot,
.theme--light.v-text-field--outline:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot:hover {
  border: 1px solid #256EFF !important;
}

.theme--light.v-text-field--outline > .v-input__control > .v-input__slot {
  border: 1px solid #256EFF !important;
}

.notranslate {
  transform: none !important;
}

.fit >>> .v-select__selections input {
  max-width: 32px !important;
}

.v-menu__content .v-list {
  padding: 16px;
}

.v-menu__content .v-list-item {
  padding-left: 2px;
  min-height: 32px !important;
  height: 32px !important;
}


.v-menu__content .v-subheader {
  height: 24px !important;
  font-size: 12px;
  background-color: #CCF3FF;
  border-radius: 4px;
  color: rgb(37, 110, 255) !important;
  font-weight: 600 !important;
  max-width: fit-content;
}


.v-menu__content .v-subheader:not(:first-child) {
  margin-top: 20px;
}

.v-menu__content .v-list-item__title {
  /*text-transform: lowercase;*/
  font-family: Open Sans, sans-serif !important;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  border-radius: 8px;
  color: #0c193a;
  display: block;
  letter-spacing: .3px;
}

.v-autocomplete__content {
  border-radius: 10px !important;
}

</style>
