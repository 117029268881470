import Vue from 'vue'

export default class cookieClass {

  private readonly m_cookie_name: string;

  private m_value: any = null;

  constructor(name: string) {
    this.m_cookie_name = name;
  }


  get value() {
    return this.m_value;
  }

  set value(value: any) {
    Vue.$cookies.set(this.m_cookie_name, value)
    this.m_value = value;
  }

  /**
   * Set initial values on start the Vue project
   */
  public setInitialValues(): void {
    if (Vue.$cookies.isKey(this.m_cookie_name))
      this.m_value = Vue.$cookies.get(this.m_cookie_name)
  }

  /**
   * Set initial values on start the Vue project
   */
  public refresh(): void {
    if (Vue.$cookies.isKey(this.m_cookie_name))
      this.m_value = Vue.$cookies.get(this.m_cookie_name)
  }

  /**
   * Remove cookie function
   */
  public remove(): void {
    Vue.$cookies.remove(this.m_cookie_name)
    this.m_value = null;
  }

  /**
   * Checks the cookie's existence
   */
  public exists(): boolean {
    return Vue.$cookies.isKey(this.m_cookie_name) || this.m_value
  }

}
