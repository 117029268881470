import Subject, {IBaseSubject} from "./BaseSubject";

/**
 * Subjects Class
 */
export default class Subjects {
  private readonly _subjects: Subject[];

  constructor(subjects: IBaseSubject[]) {
    this._subjects = subjects.map(s => new Subject(s));
  }

  get subjects(): Subject[] {
    return this._subjects
  }
}


