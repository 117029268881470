export interface IBaseSubject {
  subject_id: number;
  subject_name: string;
  subject_path: string;
  subject_icon: string;
  subject_desc?: string;
  subject_branch: number;
  subject_article_id: number;
}


export default class BaseSubject implements IBaseSubject {
  public subject_id: number;
  public subject_name: string;
  public subject_path: string;
  public subject_icon: string;
  public subject_desc: string | null;
  public subject_branch: number;
  public subject_article_id: number;

  constructor(subject: IBaseSubject) {
    this.subject_id = subject.subject_id;
    this.subject_name = subject.subject_name;
    this.subject_path = subject.subject_path;
    this.subject_icon = subject.subject_icon;
    this.subject_desc = subject.subject_desc;
    this.subject_branch = subject.subject_branch;
    this.subject_article_id = subject.subject_article_id;
  }
}
