<template>
  <v-dialog
    v-model="menu"
    fullscreen
  >
    <v-card
      class="shadow-small text-center"
      :class="$vuetify.breakpoint.smAndDown ? 'mx-6' : ''"
      color="white" tile
      style="position: relative">


      <v-chip
        style="font-size: 1.1em !important; line-height: 1.3 !important; height: 40px"
        class="font-weight-bold font-weight-medium rounded px-5 mb-6 mt-5 font-rubik"
        color="#B1DDFF"
      >
         <span class="font-weight-medium font-rubik mx-auto text-center" style="font-size: 20px">
           L'equip d'<span class="font-weight-bold" style="color: #256EFF; text-decoration: underline">examenselectivitat.cat</span> et desitja molta sort! ✨
        </span>
      </v-chip>


      <v-container
        class="d-flex flex-column pt-2 px-0" style="position: relative !important;">

        <div style="max-width: 900px" class="d-flex align-center">

          <v-btn-toggle
            v-model="horariType" class="mx-auto mx-auto mb-4"
            background-color="white" color="white"
          >
            <v-btn style="min-width: 100px;"
                   :style="horariType === 0 ? 'color: #256EFF' : 'color: black'"
                   :color="horariType === 0 ? '#CCF3FF' : 'white'">
              <span class="font-rubik text-none" style="letter-spacing: normal">Horari</span>
            </v-btn>

            <v-btn style="min-width: 100px"
                   :style="horariType === 1 ? 'color: #256EFF' : 'color: black'"
                   :color="horariType === 1 ? '#CCF3FF' : 'white'">
              <span class="font-rubik text-none" style="letter-spacing: normal">
                Horari NESE
              </span>
              <v-tooltip bottom color="black" right fixed >
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    small right
                    color="grey darken-2"
                  >{{ mdiInformationOutline() }}</v-icon>
                </template>
                L'alumnat amb necessitats específiques de suport educatiu (NESE) (dislèxia)
              </v-tooltip>
            </v-btn>
          </v-btn-toggle>

          <v-spacer></v-spacer>

          <v-btn
            @click="shareWhatsapp()"
            color="green" class="white--text rounded-pill mr-4" depressed
            :style="$vuetify.breakpoint.xs ? 'position: fixed; bottom: 10px; right: 10px' : ''"
          >
            <span class="font-rubik text-none">
              Compartir
            </span>
            <v-icon>{{ mdiWhatsapp() }}</v-icon>
          </v-btn>

          <v-icon
            @click="menu=false" right
            color="black">{{ mdiClose() }}
          </v-icon>
        </div>

        <!-------------------------------->
        <!-- PC & LAPTOPS               -->
        <!-------------------------------->

        <table
          v-if="$vuetify.breakpoint.smAndUp"
          class="styled-table rounded-lg">
          <thead class="rounded-lg">
          <tr class="rounded-t-lg">
            <td></td>
            <td class="d-flex flex-column">
              <span class="font-weight-bold">Primer dia</span>
              <span style="font-size: 12px">
              4 de juny
              </span>
              <!--               /<br>5 de setembre-->
            </td>
            <td>
              <div class="d-flex flex-column">
                <span class="font-weight-bold">Segon dia</span>
                <span style="font-size: 12px">
              5 de juny
              </span>
              </div>
              <!--               /<br>6 de setembre-->
            </td>
            <td class="d-flex flex-column">
              <span class="font-weight-bold">Tercer dia</span>
              <span style="font-size: 12px">
              6 de juny
              </span>
              <!--               /<br>7 de setembre-->
            </td>
          </tr>
          </thead>


          <tr style="background-color: #ededed; color: #256EFF" class="font-weight-medium active-row">
            <td v-if="!horariType">
              8:00 - 9:00
            </td>
            <td v-else>
              8:00 - 9:00
            </td>
            <td></td>
            <td>Comprovació dades alumnes</td>
            <td></td>
          </tr>

          <tr>

            <td v-if="!horariType">
              9:00 - 10:30
            </td>
            <td v-else>
              9:00 - 11:00
            </td>

            <td>Llengua castellana i literatura</td>
            <td>Història /<br>Història de la Filosofia</td>
            <td>Llengua catalana i literatura</td>
          </tr>

          <tr style="background-color: #ededed; color: #256EFF" class="font-weight-medium active-row">
            <td v-if="!horariType">
              10:30 - 12:00
            </td>
            <td v-else>
              11:00 - 12:00
            </td>

            <td></td>
            <td>Descans</td>
            <td></td>
          </tr>


          <tr>
            <td v-if="!horariType">
              12:00 - 13:30
            </td>
            <td v-else>
              12:00 - 14:00
            </td>


            <td>
              Llengua estrangera
            </td>

            <td>
              Anàlisi Musical /<br>Ciències Generals /<br>Llengua i Cultures Llatines /<br>Matemàtiques
            </td>

            <td>
              Arts Escèniques /<br>Dibuix Artístic /<br>Llengua i Cultures Gregues /<br>Matemàtiques Aplicades<br> a les Ciències Socials
            </td>
          </tr>

          <tr style="background-color: #ededed; color: #256EFF" class="font-weight-medium active-row">
            <td v-if="!horariType">
              13:30 - 15:00
            </td>
            <td v-else>
              14:00 - 15:00
            </td>

            <td></td>
            <td>Descans</td>
            <td></td>
          </tr>

          <tr>
            <td v-if="!horariType">
              15:00 - 16:30
            </td>
            <td v-else>
              15:00 - 17:00
            </td>

            <td>
              Física /<br>Fonaments Artístics /<br>Geografia /<br>Geologia i Ciències Ambientals /<br>Literatura Dramàtica
            </td>

            <td>
              Dibuix Tècnic /<br>Història de l'Art /<br>Història de la Música i de la Dansa /<br>Literatura Castellana /<br>Química
            </td>

            <td>
              Biologia /<br>Disseny /<br>Funcionament de l'Empresa <br>i Disseny de Models de Negoci /<br>Literatura Catalana /<br>Tecnologia i Enginyeria
            </td>
          </tr>


        </table>


        <!-------------------------------->
        <!-- MOBILE               -->
        <!-------------------------------->

        <table
          v-if="$vuetify.breakpoint.xs"
          class="styled-table rounded-lg">
          <thead class="rounded-lg">
          <tr class="rounded-t-lg">
            <td></td>
            <td class="d-flex flex-column">
              <span class="font-weight-bold">Primer dia</span>
              <span style="font-size: 12px">
              7 de juny
              </span>
              <!--               /<br>5 de setembre-->
            </td>
          </tr>
          </thead>


          <tr style="background-color: #ededed; color: #256EFF" class="font-weight-medium active-row">
            <td v-if="!horariType">
              8:00 - 9:00
            </td>
            <td v-else>
              8:00 - 9:00
            </td>
            <td>Comprovació dades alumnes</td>
          </tr>

          <tr>

            <td v-if="!horariType">
              9:00 - 10:30
            </td>
            <td v-else>
              9:00 - 11:00
            </td>

            <td>Llengua castellana i literatura</td>

          </tr>

          <tr style="background-color: #ededed; color: #256EFF" class="font-weight-medium active-row">
            <td v-if="!horariType">
              10:30 - 12:00
            </td>
            <td v-else>
              11:00 - 12:00
            </td>

            <td>Descans</td>
          </tr>


          <tr>
            <td v-if="!horariType">
              12:00 - 13:30
            </td>
            <td v-else>
              12:00 - 14:00
            </td>


            <td>
              Llengua estrangera
            </td>

          </tr>

          <tr style="background-color: #ededed; color: #256EFF" class="font-weight-medium active-row">
            <td v-if="!horariType">
              13:30 - 15:00
            </td>
            <td v-else>
              14:00 - 15:00
            </td>

            <td>Descans</td>
          </tr>

          <tr>
            <td v-if="!horariType">
              15:00 - 16:30
            </td>
            <td v-else>
              15:00 - 17:00
            </td>

            <td>
              Anàlisi musical /<br>
              Ciències de la Terra i Medi Ambient /<br>
              Cultura Audiovisual /<br>
              Física /<br>
              Geografia
            </td>

          </tr>


        </table>


        <table
          v-if="$vuetify.breakpoint.xs"
          class="styled-table rounded-lg">
          <thead class="rounded-lg">
          <tr class="rounded-t-lg">
            <td></td>

            <td>
              <div class="d-flex flex-column">
                <span class="font-weight-bold">Segon dia</span>
                <span style="font-size: 12px">
              8 de juny
              </span>
              </div>
              <!--               /<br>6 de setembre-->
            </td>

          </tr>
          </thead>


          <tr style="background-color: #ededed; color: #256EFF" class="font-weight-medium active-row">
            <td v-if="!horariType">
              8:00 - 9:00
            </td>
            <td v-else>
              8:00 - 9:00
            </td>
            <td>Comprovació dades alumnes</td>
          </tr>

          <tr>

            <td v-if="!horariType">
              9:00 - 10:30
            </td>
            <td v-else>
              9:00 - 11:00
            </td>

            <td>Història</td>
          </tr>

          <tr style="background-color: #ededed; color: #256EFF" class="font-weight-medium active-row">
            <td v-if="!horariType">
              10:30 - 12:00
            </td>
            <td v-else>
              11:00 - 12:00
            </td>

            <td>Descans</td>
          </tr>


          <tr>
            <td v-if="!horariType">
              12:00 - 13:30
            </td>
            <td v-else>
              12:00 - 14:00
            </td>


            <td>
              Dibuix artístic /<br>
              Llatí /<br>
              Matemàtiques
            </td>


          </tr>

          <tr style="background-color: #ededed; color: #256EFF" class="font-weight-medium active-row">
            <td v-if="!horariType">
              13:30 - 15:00
            </td>
            <td v-else>
              14:00 - 15:00
            </td>

            <td>Descans</td>
          </tr>

          <tr>
            <td v-if="!horariType">
              15:00 - 16:30
            </td>
            <td v-else>
              15:00 - 17:00
            </td>


            <td>
              Dibuix tècnic /<br>
              Història de l'art /<br>
              Història de la filosofia /<br>
              Literatura castellana /<br>
              Química
            </td>


          </tr>


        </table>


        <table
          v-if="$vuetify.breakpoint.xs"
          class="styled-table rounded-lg">
          <thead class="rounded-lg">
          <tr class="rounded-t-lg">
            <td></td>

            <td class="d-flex flex-column">
              <span class="font-weight-bold">Tercer dia</span>
              <span style="font-size: 12px">
              9 de juny
              </span>
              <!--               /<br>7 de setembre-->
            </td>
          </tr>
          </thead>


          <tr style="background-color: #ededed; color: #256EFF" class="font-weight-medium active-row">
            <td v-if="!horariType">
              8:00 - 9:00
            </td>
            <td v-else>
              8:00 - 9:00
            </td>
            <td>Comprovació dades alumnes</td>
          </tr>

          <tr>

            <td v-if="!horariType">
              9:00 - 10:30
            </td>
            <td v-else>
              9:00 - 11:00
            </td>


            <td>Llengua catalana i literatura</td>
          </tr>

          <tr style="background-color: #ededed; color: #256EFF" class="font-weight-medium active-row">
            <td v-if="!horariType">
              10:30 - 12:00
            </td>
            <td v-else>
              11:00 - 12:00
            </td>

            <td>Descans</td>
          </tr>


          <tr>
            <td v-if="!horariType">
              12:00 - 13:30
            </td>
            <td v-else>
              12:00 - 14:00
            </td>


            <td>
              Electrotècnia /<br>
              Fonaments de les Arts /<br>
              Grec /<br>
              Matemàtiques CCSS
            </td>
          </tr>

          <tr style="background-color: #ededed; color: #256EFF" class="font-weight-medium active-row">
            <td v-if="!horariType">
              13:30 - 15:00
            </td>
            <td v-else>
              14:00 - 15:00
            </td>

            <td>Descans</td>
          </tr>

          <tr>
            <td v-if="!horariType">
              15:00 - 16:30
            </td>
            <td v-else>
              15:00 - 17:00
            </td>

            <td>
              Biologia /<br>
              Disseny /<br>
              Economia de l'empresa /<br>
              Literatura catalana /<br>
              Tecnologia Industrial
            </td>
          </tr>


        </table>




        <v-btn
          @click="menu=false"
          class="px-12 text-none font-weight-bold font-rubik rounded-lg white--text solution-button mx-auto mt-10"
          depressed
          height="46"
          color="primary"
          style="width: fit-content"
        >
          Tancar
        </v-btn>


        <!-------------------------------------->
        <!-- ANUNCI                           -->
        <!-------------------------------------->
        <v-card
          v-if="$root.currentUser === null || !$root.currentUser.premium"
          flat color="white"
          class="mt-6 rounded-lg" min-height="100">

          <!-- HORITZONTAL AD -->
          <adsense ad-slot="8353107512"></adsense>

        </v-card>

      </v-container>

    </v-card>
  </v-dialog>

</template>

<script>
import {mdiCalendar, mdiChevronRight, mdiClose, mdiInformationOutline, mdiWhatsapp} from "/src/assets/mdi.json";
import Adsense from "../anuncis/Adsense.vue";

export default {
  name: "Horari",
  components: {Adsense},
  data() {
    return {
      menu: false,
      horariType: 0,
      horari: [

      ]
    }
  },
  methods: {
    shareWhatsapp() {
      const path = this.$route.params.article_path;
      window.open(`whatsapp://send?text=👋 Horari de la Selectivitat 2025 (https://examenselectivitat.cat/?horari=true)`)
    },

    mdiWhatsapp() {
      return mdiWhatsapp
    },
    mdiInformationOutline() {
      return mdiInformationOutline
    },
    mdiClose() {
      return mdiClose
    },
    mdiCalendar() {
      return mdiCalendar
    },
    mdiChevronRight() {
      return mdiChevronRight
    }
  },
  props: {
    value: Boolean
  },
  watch: {
    menu(val) {
      this.$emit("update:value", val)
      if (val && !this.$route.query.horari) {
        this.$router.push({path: this.$route.path, query: {horari: true} });
      } else {
        this.$router.push({path: this.$route.path, query: {} });
      }
    },
    value(val) {
      this.menu = val;
    },
    '$route.query': {
      immediate: true,
      handler(val) {
        if (val.horari)
          this.menu=true
      }
    },
  }
}
</script>

<style scoped>
.styled-table {
  border-collapse: collapse;
  font-size: 0.9em;
  font-family: Rubik, sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #256EFF;
  color: #ffffff;
  text-align: left;
  font-family: Rubik, sans-serif;
  vertical-align: top;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
  font-family: Rubik, sans-serif;
  text-align: center;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
  font-family: Rubik, sans-serif;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
  font-family: Rubik, sans-serif;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #256EFF;
  font-family: Rubik, sans-serif;
}

.styled-table tbody tr.active-row {
  font-family: Rubik, sans-serif;
  color: #256EFF;
}

.styled-table .active-row td {
  padding: 4px !important;
}
</style>
