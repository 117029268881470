import HomePage from "./views/home/HomePage";
// import SubjectPage from "./views/examens/subject/SubjectPage";
// import Problems from "./views/examens/problems/Problems";

const routes = [

  /**
   * USERS
   */
  {
    path: '/login',
    component: () => import('./views/profile/login/LoginPage.vue')
  },
  /**
   * PROFILE
   */
  {
    path: '/perfil',
    component: () => import('./views/profile/ProfilePage.vue'),
    name: 'Profile'
  },
  {
    path: '/unsubscribe-email/:user_email',
    component: () => import('./views/profile/email/EmailUnsubscribe.vue'),
    name: 'EmailUnsubscribe'
  },
  {
    path: '/email-sender',
    component: () => import('./views/profile/email/EmailPage.vue'),
    name: 'EmailSender'
  },
  /**
   * SHOP
   */
  //--------------------------------------------------------
  // PREMIUM
  //--------------------------------------------------------
  {
    path: '/premium',
    component: () => import('./views/premium/PremiumPage.vue'),
    name: 'Premium'
  },
  {
    path: '/premium/success',
    component: () => import('./views/premium/SuccessfulSubscription.vue'),
    name: 'SuccessfulSubscription'
  },
  {
    path: '/premium/cancel',
    component: () => import('./views/premium/CancelledSubscription.vue'),
    name: 'CancelledSubscription'
  },
  //--------------------------------------------------------
  // DOSSIERS
  //--------------------------------------------------------
  {
    path: '/checkout',
    component: () => import('./views/shop/checkout/CheckOutPage.vue'),
    name: 'CheckOut'
  },
  {
    path: '/dossiers-selectivitat',
    component: () => import('./views/shop/ShopPage.vue'),
    name: 'ShopPage'
  },
  {
    path: '/dossiers-selectivitat/book/:book_id',
    component: () => import('./views/shop/BookPage.vue'),
    name: 'BookPage'
  },
  {
    path: '/dossiers-selectivitat/success',
    component: () => import('./views/shop/success/SuccessfulPayment.vue'),
    name: 'SuccessfulPayment'
  },
  /**
   * SETTINGS
   */
  {
    path: '/perfil/configuracio',
    component: () => import('./views/profile/configuration/ConfigPage.vue'),
    name: 'Configuracio'
  },
  /**
   * PROBLEM UPLOADER
   */
  {
    path: '/admin',
    component: () => import('./views/admin/AdminPage.vue')
  },
  /**
   * COLLECTIONS
   */
  {
    path: '/collections/:collection_id',
    component: () => import('./views/profile/CollectionPage.vue'),
    name: 'CollectionPage'
  },
  /**
   * CALCULADORA
   */
  {
    path: '/calculadora-selectivitat',
    component: () => import('./views/calculadores/CalculadoraNota.vue')
  },

  /**
   * PONDERACIONS
   */
  {
    name: 'Ponderacions',
    path: '/notes-de-tall',
    component: () => import('./views/ponderacions/Ponderacions.vue')
  },
  /**
   * CONTACTE
   */
  {
    name: 'Contacte',
    path: '/contacte',
    component: () => import("./views/contacte/Contacte.vue")
  },
  // BLOG
  {
    name: 'Blog',
    path: '/blog',
    component: () => import('./views/blog/BlogPage.vue')
  },
  // BLOG PUBLISH ARTICLE
  {
    name: 'PublishArticlePage',
    path: '/blog/publish-article/:id',
    component: () => import('./views/blog/PublishArticlePage.vue')
  },
  // BLOG VIEW ARTICLE
  {
    name: 'PublishArticlePage',
    path: '/blog/article/:article_path',
    component: () => import('./views/blog/ArticlePage.vue')
  },
  // Donacions
  {
    name: 'Donacions',
    path: '/donacions',
    component: () => import('./views/donacions/Donacions.vue')
  },
  // Donacions Failed
  {
    name: 'Donacions',
    path: '/donacions/failed',
    component: () => import('./views/donacions/Failed.vue')
  },
  // Donacions Success
  {
    name: 'Donacions',
    path: '/donacions/success',
    component: () => import('./views/donacions/Success.vue')
  },
  /**
   * EXAMENS SELECTIVITAT
   */

  // Selecat
  {
    name: 'Selecat',
    path: '/selecat',
    component: () => import('./views/examens/Selecat.vue')
  },
  // Practicar Homepage
  {
    name: 'HomePage',
    path: '/selectivitat',
    component: HomePage
  },
  // Assignatura
  {
    name: 'Subject',
    path: '/selectivitat/:subject_name',
    component: () => import("./views/examens/subject/SubjectPage")
  },
  // Examens Tema & Subtema, Any
  {
    name: 'ExamsPerTemes',
    path: '/selectivitat/:subject_name/per-temes/:topic_path',
    component: () => import('./views/examens/problems/ProblemesPerTemes.vue')
  },
  {
    name: 'ExamsPerAny2',
    path: '/selectivitat/:subject_name/examens/:year/:mes/:serie/:problem_id',
    component: () => import('./views/examens/problems/RedirectProblems.vue')
  },
  {
    name: 'ExamsPerAny',
    path: '/selectivitat/:subject_name/examens/:year/:mes/:serie',
    component: () => import('./views/examens/problems/RedirectProblems.vue')
  },
  {
    path: '/selectivitat/:subject_name/pdf/:year/:month/:locale',
    component: () => import('./views/examens/problems/PracticarPDF.vue'),
    name: "ExamPDF"
  },
  // Cercador
  {
    path: '/cercador-selectivitat/:subject_id/:search',
    component: () => import('./views/cercador/SearchPage.vue')
  },
  // ---------------------------------
  // EXAMS PAGE
  {
    path: '/selectivitat/:subject_name/per-temes/:topic_path/:problem_id',
    component: () => import("./views/examens/problems/RedirectProblems.vue")
  },
  {
    path: '/selectivitat/:subject_name/examens/:year/:mes',
    component: () => import("./views/examens/problems/ProblemesPerAny.vue")
  },
  {
    path: '/upload/images',
    component: () => import('./views/admin/UploadImages.vue'),
  },

  /**
   * ADS
   */
  {
    path: '/review/:stripe_id/:rating',
    component: () => import('./views/reviews/ReviewPage.vue'),
    name: 'ReviewPage'
  },
  /**
   * POLÍTIQUES
   */
  {
    path: '/politica-de-cookies',
    component: () => import('./views/politiques/Cookies.vue')
  },
  {
    path: '/politica-de-privicitat',
    component: () => import('./views/politiques/Privicitat.vue')
  },
  {
    path: '/avis-legal',
    component: () => import('./views/politiques/Legal.vue')
  },
  {
    path: '/condicions-generals',
    component: () => import('./views/politiques/CondicionesGenerales.vue')
  },
  /**
   * PROFESSORS
   */
  {
    path: '/professors-particulars-batxillerat',
    component: () => import('./views/professors/Profes.vue'),
    name: 'Profes'
  },
  /**
   * HOME PAGE
   */
  {
    path: '/',
    component: () => import('./views/home/HomePage.vue'),
    name: 'Inici'
  },

  /**
   * 404
   */
  {
    path: '/404',
    component: () => import('./views/politiques/NotFoundComponent.vue')
  },
  {
    path: '*',
    redirect: '/404'
  }
]

export default routes
