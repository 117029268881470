<template>
	<v-footer
		padless
	>
		<v-sheet
			tile
			width="100%"
			color="#F8F9FD"
			class="text-center pb-10 pt-5 black--text"
		>
			<v-row class="pa-10 pt-3 pb-0 black--text" style="max-width: 1034px; margin: auto">

				<v-col cols="12" md="5" :class="isMobile ? 'text-center' : 'text-start'">
					<p class="tit black--text font-weight-bold"
					   style="font-size: 20px"
					>
						Polítiques
					</p>
					<p @click="goTo('condicions-generals')"
					   class="pb-0 link body-1 black--text">
						Condicions Generals
					</p>
					<p @click="goTo('politica-de-cookies')"
					   class="pb-0 link body-1 black--text">
						Política de Cookies
					</p>
					<p @click="goTo('politica-de-privicitat')"
					   class="pb-0 link body-1">
						Política de Privicitat
					</p>
					<p @click="goTo('avis-legal')"
					   class="pb-0 link body-1">
						Avís Legal
					</p>

					<v-card-text>
					</v-card-text>
				</v-col>
				<v-col cols="12" md="3" class="text--secondary" :class="isMobile ? 'text-center' : 'text-start'">
					<p class="tit black--text font-weight-bold"
					   style="font-size: 20px"
					>
						Menú
					</p>
					<p @click="goTo('selectivitat')"
					   class="pb-0 link body-1">
						Exàmens
					</p>
					<p @click="goTo('notes-de-tall')"
					   class="pb-0 link body-1">
						Notes de tall
					</p>
					<p @click="goTo('calculadora-selectivitat')"
					   class="pb-0 link body-1">
						Calculadora
					</p>
				</v-col>
				<v-col cols="12" md="4" :class="isMobile ? 'text-center' : 'text-start'">
					<p class="tit font-weight-bold"
					   style="font-size: 20px"
					>
						Sobre nosaltres
					</p>


					<router-link :to="{name: 'Contacte'}">
						<p class="pb-0 link body-1">
							Contacta'ns
						</p>
					</router-link>


					<p class="pt-0 link body-1">
						<a href="mailto:contacte@examenselectivitat.cat" target="_top" class="black--text">
							contacte@examenselectivitat.cat
						</a>
					</p>


				</v-col>
			</v-row>

			<v-card-text>
				<a v-for="(icon, idx) in icons"
				   target="_blank"
				   :href="links[idx]">
					<v-btn
						:key="icon"
						class="mr-3 app"
						icon
						:aria-label="iconNames[idx]"
					>
						<v-icon size="24px" color="grey darken-3">{{ icon }}</v-icon>
					</v-btn>
				</a>
			</v-card-text>

			<div class="mb-3">
      <span style="font-size: 15px" class="font-rubik">No perdis el temps, utlitza
        <span class="font-rubik font-weight-medium">examenselectivitat.cat</span>.</span>
			</div>

			<span style="font-size: 13px" class="font-rubik grey--text text--darken-2">
		  La font dels exàmens prové de la Generalitat de Catalunya.
		  </span>
			<br>
			<br>

			<span class="font-rubik mt-4" style="font-size: 15px">© 2019-{{ new Date().getFullYear() }}, examenselectivitat.cat</span>

		</v-sheet>
	</v-footer>
</template>

<script>
import {mdiTwitter} from '/src/assets/mdi.json'
import {mdiInstagram} from '/src/assets/mdi.json'

export default {
	data: () => ({
		iconNames: [
			"twitter x",
			"instagram",
		],
		icons: [
			mdiTwitter,
			mdiInstagram,
		],
		links: [
			'https://twitter.com/examensele?ref_src=twsrc%5Etfw',
			'https://www.instagram.com/examenselectivitat/'
		]
	}),
	methods: {
		goTo: function (row) {
			this.$router.push({path: `/${row}`})
		}
	},
	computed: {
		isMobile: function () {
			return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
		}
	}
}
</script>

<style scoped>
.link {
	cursor: pointer;
	color: black !important;
}

.link:hover {
	text-decoration: underline;
}

.app {
	cursor: pointer;
	transition: all .2s ease-in-out;
}

.app:hover {
	transform: scale(1.2);
}
</style>
