import Vue from "vue"
import Vuetify from "vuetify/lib"
// import {Scroll, Ripple, ClickOutside, Intersect} from "vuetify/lib/directives"
import {Scroll, Ripple, ClickOutside, Intersect} from "vuetify/lib/directives"

import * as mdi from "/src/assets/mdi.json"


Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: "md",
    values: {
      // Subjects
      "mdi-chess-queen": mdi.mdiChessQueen,
      "mdi-chess-rook": mdi.mdiChessRook,
      "mdi-fountain-pen-tip": mdi.mdiFountainPenTip,
      "mdi-book-open-variant": mdi.mdiBookOpenVariant,
      "mdi-bacteria": mdi.mdiBacteria,
      "mdi-image-filter-vintage": mdi.mdiImageFilterVintage,
      "mdi-android-studio": mdi.mdiAndroidStudio,
      "mdi-chip": mdi.mdiChip,
      "mdi-rocket": mdi.mdiRocket,
      "mdi-flask-empty": mdi.mdiFlaskEmpty,
      "mdi-pi-box": mdi.mdiPiBox,
      "mdi-boom-gate-up": mdi.mdiBoomGateUp,
      "mdi-music": mdi.mdiMusic,
      "mdi-camera-image": mdi.mdiCameraImage,
      "mdi-auto-fix": mdi.mdiAutoFix,
      "mdi-palette": mdi.mdiPalette,
      "mdi-movie-open": mdi.mdiMovieOpen,
      "mdi-currency-usd": mdi.mdiCurrencyUsd,
      "mdi-earth": mdi.mdiEarth,
      "mdi-alpha": mdi.mdiAlpha,
      "mdi-skull-outline": mdi.mdiSkullOutline,
      "mdi-wind-turbine": mdi.mdiWindTurbine,
      "mdi-lighthouse": mdi.mdiLighthouse,
      "mdi-pillar": mdi.mdiPillar,
      "mdi-equalizer": mdi.mdiEqualizer,
      "mdi-brush": mdi.mdiBrush,
      "mdi-pizza": mdi.mdiPizza,
      "mdi-food-croissant": mdi.mdiFoodCroissant,
      "mdi-glass-mug-variant": mdi.mdiGlassMugVariant,
      "mdi-lightbulb-on-outline": mdi.mdiLightbulbOnOutline,
      "mdi-bookshelf": mdi.mdiBookshelf,
      "mdi-dance-ballroom": mdi.mdiDanceBallroom,
      "mdi-web": mdi.mdiWeb,
      "mdi-drama-masks": mdi.mdiDramaMasks,

      // Vuetify
      "complete": mdi.mdiCheck,
      "cancel": mdi.mdiClose,
      "close": mdi.mdiClose,
      "delete": mdi.mdiDelete,
      "clear": mdi.mdiClose,
      "success": mdi.mdiCheckCircle,
      "info": mdi.mdiInformation,
      "warning": mdi.mdiPriorityHigh,
      "error": mdi.mdiAlertOutline,
      "prev": mdi.mdiChevronLeft,
      "next": mdi.mdiChevronRight,
      "checkboxOn": mdi.mdiCheckboxOutline,
      "checkboxOff": mdi.mdiCheckboxBlankOutline,
      "checkboxIndeterminate": mdi.mdiCheckboxBlank,
      "sort": mdi.mdiSortAscending,
      "expand": mdi.mdiChevronDown,
      "menu": mdi.mdiMenu,
      "dropdown": mdi.mdiMenuDown,
      "radioOn": mdi.mdiRadioboxMarked,
      "radioOff": mdi.mdiRadioboxBlank,
      "edit": mdi.mdiPencil,
      "ratingEmpty": mdi.mdiStarOutline,
      "ratingFull": mdi.mdiStar,
      "ratingHalf": mdi.mdiStarHalf,
      "loading": mdi.mdiCached,
      "first": mdi.mdiPageFirst,
      "last": mdi.mdiPageLast,
      "unfold": mdi.mdiUnfoldLessHorizontal,
      "file": mdi.mdiFile,
      "plus": mdi.mdiPlus,
      "minus": mdi.mdiMinus,
    }
  },
  theme: {
    themes: {
      light: {
        primary: "#256EFF",
      },
    },
  },
  directives: {
    Ripple,
    Scroll,
    ClickOutside,
    Intersect
  }
})
