import Vue from "vue";
import cookieClass from "./cookieClass";
import userCookie from "./userCookie";

export class CookieController extends Vue{

  private readonly m_user: userCookie;
  private readonly m_token: cookieClass;

  constructor() {
    super();

    // User cookie
    this.m_user = new userCookie('user');

    // Token cookie
    this.m_token = new cookieClass('token');

  }

  /**
   * User Getter
   */
  get user(): userCookie {
    return this.m_user
  }

  /**
   * Token Getter
   */
  get token(): cookieClass {
    return this.m_token
  }

  /**
   * Set initial values on start the Vue project
   */
  public setInitialValues(): void {
    this.m_token.setInitialValues();
    this.m_user.setInitialValues();
  }


}

const cookieController = new CookieController();

export default cookieController;
