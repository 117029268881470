<template>
  <a :href="getGoogleUrl()"
     style="width: 100%"
  >
    <v-card block height="40" width="100%"
            class="mx-auto mt-4 text-none font-rubik shadow-sm d-flex align-center text-center justify-center"
            style="letter-spacing: normal; font-size: 16px; width: 100%; min-width: 100%; border: thin solid #f2f2f2"
    >
      <div style="height: 44px; width: 24px"
           class="d-flex align-center mr-2"
      >
        <google-logo></google-logo>
      </div>
      Google
    </v-card>
  </a>
</template>

<script>
import GoogleLogo from "../assets/GoogleLogo.vue";

export default {
  name: "GoogleButton",
  components: {GoogleLogo},
  props: {
    redirect: {
      type: String,
      default: "/"
    }
  },
  methods: {
    getGoogleUrl() {
      const rootUrl = `https://accounts.google.com/o/oauth2/v2/auth`;
      const redirect = process.env.VUE_APP_GOOGLE_OAUTH_REDIRECT;
      const client_id = process.env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID;
      const options = {
        redirect_uri: redirect,
        client_id: client_id,
        access_type: 'offline',
        response_type: 'code',
        prompt: 'consent',
        scope: [
          'https://www.googleapis.com/auth/userinfo.profile',
          'https://www.googleapis.com/auth/userinfo.email',
        ].join(' '),
        state: this.redirect,
      };

      console.log("options", options)

      const qs = new URLSearchParams(options);

      return `${rootUrl}?${qs.toString()}`;
    }
  }
}
</script>

<style scoped>

</style>
