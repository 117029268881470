<template>

  <div>
    <v-overlay v-if="loading" opacity="0.2">
      <pulseLoader :loading="true" style="width: 30px; margin: 40px auto"></pulseLoader>
    </v-overlay>


    <v-card max-width="550"
            width="100%"
            style="margin: auto"
            flat
            class="align-self-center text-center shadow-small rounded-xl"
    >

      <h1 style="color: #256EFF"
          :style="$vuetify.breakpoint.smAndDown ? 'font-size: 2rem !important' : 'font-size: 2.5rem !important'"
          class="font-weight-bold pt-8">
        examenselectivitat
      </h1>

      <!---------------------------------->
      <!-- LOGIN                        -->
      <!---------------------------------->

      <v-sheet v-if="controller.authClass.loginStatus"
               class="pa-3"
      >

        <form
          class="pa-5 pb-3"
          v-if="controller.authClass.loginStatus"
          @submit.prevent="onSignIn(loginForm.email, loginForm.password)"
        >

          <!---------------------------------->
          <!-- EXAMENSELE LOG IN            -->
          <!---------------------------------->

          <v-text-field
            flat
            outlined
            color="amber lighten-1"
            id="email"
            type="text"
            placeholder="Correu electrònic"
            v-model="loginForm.email"
          >
          </v-text-field>
          <v-text-field
            outlined
            flat
            color="amber lighten-1"
            id="password"
            type="password"
            placeholder="Contrasenya"
            v-model="loginForm.password"
            :append-icon="value ? mdiEye : mdiEyeOff"
            @click:append="() => (value = !value)"
            :type="value ? 'password' : 'text'"
          >
          </v-text-field>


          <div class="pa-0 justify-end">
            <v-btn type="submit"
                   right
                   :disabled="!loginForm.email || !loginForm.password"
                   width="100%"
                   height="40"
                   style="letter-spacing: normal; font-size: 16px"
                   class="text-none text-center white--text"
                   color="primary font-rubik"
                   depressed
            >Iniciar sessió
            </v-btn>
            <div class="text-start">
              <span v-if="errorMessage" class="red--text caption">{{ errorMessage }}</span>
            </div>
          </div>
        </form>


        <!---------------------------------->
        <!-- GOOGLE LOG IN                -->
        <!---------------------------------->
        <div class="d-flex flex-column px-5 text-center justify-center align-center">
          <span class="font-rubik mt-6 font-weight-medium">O inicia sessió amb:</span>
          <google-button :redirect="redirect"
          ></google-button>
        </div>


        <!-------- GO TO REGISTER ------------------------>
        <v-card-actions class="pt-12 justify-center">
          <v-card-title @click="controller.authClass.loginStatus = false"
                        style="cursor: pointer"
                        class="pa-0 subtitle-1 font-weight-medium blue--text darken-5"
          >
            Crear nou compte
          </v-card-title>
        </v-card-actions>
      </v-sheet>


      <!---------------------------------->
      <!-- REGISTER FORM                -->
      <!---------------------------------->
      <v-stepper v-model="controller.authClass.step"
                 v-else
      >
        <v-sheet class="pa-3 pb-0 pt-8"
                 style="margin: auto; border-radius: 10px"
        >

          <v-stepper-items>

            <v-stepper-content step="1"
                               class="pa-0"
            >
              <div style="min-height: 350px">

                <form
                  class="px-5 pb-3"
                  @submit.prevent="onRegister"
                >
                  <v-row no-gutters class="pb-0 pt-2">
                    <v-col cols="12" class="pb-0 pr-1">
                      <v-text-field
                        flat
                        outlined
                        color="amber lighten-1"
                        id="name"
                        type="text"
                        placeholder="Nom"
                        v-model="registerForm.name"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-text-field
                    flat
                    outlined
                    id="email"
                    color="amber lighten-1"
                    type="text"
                    class="pt-1"
                    placeholder="Correu electrònic"
                    v-model="registerForm.email"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    flat
                    color="amber lighten-1"
                    type="password"
                    :append-icon="value ? mdiEye : mdiEyeOff"
                    @click:append="passVisibility()"
                    :type="value ? 'password' : 'text'"
                    placeholder="Contrasenya"
                    v-model="registerForm.password1"
                    class="pt-1"
                  >
                  </v-text-field>
                  <v-text-field
                    outlined
                    color="amber lighten-1"
                    flat
                    :append-icon="value2 ? mdiEye : mdiEyeOff"
                    @click:append="passVisibility2()"
                    :type="value2 ? 'password' : 'text'"
                    placeholder="Repeteix la contrasenya"
                    v-model="registerForm.password2"
                    class="pt-1 pb-0"
                  >
                  </v-text-field>
                  <v-card-text class="red--text pa-0 text-end"
                               v-if="registerForm.password1 !== registerForm.password2"
                  >
                    Les contrasenyes han de coincidir.
                  </v-card-text>

                  <v-checkbox v-model="checkbox">
                    <template v-slot:label>
                      <div>
                        Accepto les
                        <v-tooltip color="black">
                          <template v-slot:activator="{ on }">
                            <a
                              target="_blank"
                              href="https://examenselectivitat.cat/avis-legal"
                              @click.stop
                              v-on="on"
                            >
                              Condicions
                            </a>
                          </template>
                        </v-tooltip>
                        i la
                        <v-tooltip color="black">
                          <template v-slot:activator="{ on }">
                            <a
                              target="_blank"
                              href="https://examenselectivitat.cat/politica-de-privicitat"
                              @click.stop
                              v-on="on"
                            >
                              Política de privicitat
                            </a>
                          </template>
                        </v-tooltip>
                      </div>
                    </template>
                  </v-checkbox>
                </form>
              </div>

              <v-container class="pa-5 pt-0 justify-end">
                <div class="text-start">
                  <span v-if="errorMessage" class="red--text caption">{{ errorMessage }}</span>
                </div>
                <v-btn type="submit"
                       right
                       @click="onRegister"
                       :disabled="!canRegister"
                       width="100%"
                       height="40"
                       class="text-none text-center white--text subtitle-2"
                       color="primary"
                       depressed
                >Registra't
                </v-btn>
                <br>
                <v-btn type="submit"
                       right
                       @click="controller.authClass.loginStatus = true"
                       width="100%"
                       height="40"
                       outlined
                       class="text-none text-center white--text subtitle-2 mt-4"
                       color="grey lighten-2"
                       depressed
                >
                  <span class="blue-grey--text text--darken-4">Ja tens compte? <a>Iniciar sessió</a></span>
                </v-btn>
              </v-container>
            </v-stepper-content>

            <v-stepper-content step="2"
                               class="pa-0"
            >
              <div style="min-height: 350px">

                <v-card-title class="justify-start tit font-weight-bold title pt-0 pl-5"> Per la teva
                  comoditat
                </v-card-title>

                <div class="pa-5 pt-2">
                  <v-text-field
                    flat
                    outlined
                    color="amber lighten-1"
                    type="text"
                    class="pa-0"
                    placeholder="Nom d'usuari (àlies)"
                    v-model="registerForm.nickname"
                  >
                  </v-text-field>

                  <v-autocomplete
                    v-model="registerForm.institut"
                    :items="instituts"
                    clearable
                    class=""
                    hide-details
                    color="amber lighten-1"
                    hide-selected
                    placeholder="En quin institut estudies"
                    outlined
                  >
                  </v-autocomplete>

                  <v-autocomplete
                    v-model="registerForm.branca"
                    :items="['Científic i Tecnològic', 'Social i Humanístic', 'Artístic', 'Més d\'una branca..']"
                    clearable
                    class="pt-7"
                    hide-details
                    color="amber lighten-1"
                    hide-selected
                    placeholder="Quina branca curses"
                    outlined
                  >
                  </v-autocomplete>
                  <v-autocomplete
                    v-model="registerForm.assignatures"
                    :items="assignatures"
                    chips
                    multiple
                    class="pt-7 mb-12"
                    single-line
                    clearable
                    hide-details
                    color="amber lighten-1"
                    hide-selected
                    item-text="name"
                    item-value="name"
                    placeholder="Assignatures específiques"
                    outlined
                  >

                    <template v-slot:selection="{ attr, on, item, selected }">
                      <v-chip
                        v-bind="attr"
                        :input-value="selected"
                        color="indigo"
                        class="white--text"
                        v-on="on"
                        close
                        @click:close="close(item.name)"
                      >
                        <span v-text="item.name"></span>
                      </v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-action>
                        <v-icon color="indigo">{{ item.icon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-medium"
                                           v-text="item.name"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>

                </div>
              </div>

              <v-container class="pr-5 pl-5 pt-0 pb-0 justify-end">
                <v-btn type="submit"
                       right
                       @click="addAssignatura"
                       :disabled="somhi"
                       width="100%"
                       height="40"
                       class="text-none text-center white--text subtitle-2 mt-12"
                       color="primary"
                       depressed
                >Som-hi
                </v-btn>
                <br>
                <v-btn type="submit"
                       right
                       @click="toLater"
                       width="100%"
                       height="40"
                       outlined
                       class="text-none text-center white--text subtitle-2 mt-4 mb-5"
                       color="grey lighten-2"
                       depressed
                >
                                    <span class="blue-grey--text text--darken-4"
                                    >
                                        Més endevant
                                    </span>
                </v-btn>
              </v-container>

            </v-stepper-content>

          </v-stepper-items>
        </v-sheet>

      </v-stepper>
    </v-card>

    <!----------------------------->
    <!-- Reset password dialog   -->
    <!----------------------------->
    <v-dialog v-model="resetPass" max-width="600">
      <v-card v-if="loginForm && loginForm.email" width="600" class="py-6 px-10">
        <h1>Confirma el teu compte</h1>
        <p>T'acabem d'enviar un email a <span style="font-style: italic">{{ loginForm.email }}</span> per confirmar
          que
          ets tu.
          És possible que el missatge t'arribi com a Spam (Correu brossa).</p>
        <p>Introdueix el codi:</p>
        <v-text-field v-model="resetCodePass"
                      solo class="mx-auto px-auto"
                      style="max-width: 240px"
        ></v-text-field>

        <v-card-actions class="d-flex flex-column text-center justify-center">
              <span v-if="errorMessageCodePass"
                    class="red--text mx-auto caption mb-10">El codi no és correcte, et queden {{ confirmPassTimes }} intents...</span>
          <v-btn color="primary" depressed
                 :disabled="!resetCodePass"
                 width="140"
                 @click="confirmPassCode"
                 class="text-none mx-auto tit" large>Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mdiEye} from '/src/assets/mdi.json'
import {mdiEyeOff} from '/src/assets/mdi.json'

import Loader from '../../../../components/VLoader'
import controller from "@/store/Controller";
import GoogleButton from "./GoogleButton.vue";

export default {
  components: {
    GoogleButton,
    pulseLoader: Loader
  },
  props: {
    redirect: {
      type: String,
      default: "/"
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  mounted() {
    console.log("this.getUser", this.getUser)
    if (this.getUser) {
      this.afterLog()
    }
  },
  data() {
    return {
      mdiEye: mdiEye,
      mdiEyeOff: mdiEyeOff,
      prevRoute: null,
      resetCodePass: null,
      resetCodePassReal: null,
      controller: controller,
      value: true,
      value2: true,
      text: [
        'Iniciar sessió',
        'Crear nou compte'
      ],
      loginForm: {
        email: '',
        password: ''
      },
      registerForm: {
        name: '',
        email: '',
        password1: '',
        password2: '',
        branca: '',
        assignatures: [],
        nickname: '',
        institut: ''
      },
      errors: null,
      showErrors: false,
      local_dialog: null,
      userId: null,
      invalidEmail: false,
      checkbox: false,
      login: false,
      addedAssig: false,
      instituts: null,
      loading: false,
      activated: false,
      resetPass: false,
      errorMessage: null,
      confirmPassTimes: 5,
      errorMessageCodePass: false
    }
  },
  computed: {
    getUser() {
      return this.$root.currentUser.value
    },
    prevRoutePath() {
      return this.prevRoute ? this.prevRoute.path : '/'
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'md'
    },
    checkDoublePass: function () {
      return (this.registerForm.password1 === this.registerForm.password2) && this.registerForm.password2.length > 6
    },
    getInitial() {
      return this.registerForm.name.substring(0, 1)
    },
    getRandomColor() {
      return '#' + Math.floor(Math.random() * 16777215).toString(16)
    },
    assignatures() {
      return null
    },
    canRegister() {
      return this.checkDoublePass &&
        this.checkbox &&
        this.registerForm.name !== '' &&
        (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.registerForm.email))
    },
    somhi() {
      if (!this.registerForm.branca ||
        !this.registerForm.assignatures ||
        !this.registerForm.institut ||
        !this.registerForm.nickname ||
        this.registerForm.assignatures.length === 0 ||
        this.registerForm.assignatures === []) {
        return true
      } else return false
    },
    isUserInfo() {
      return (this.getUser.branca && this.getUser.assignatures && this.getUser.nickname && this.getUser.institut)
    }
  },

  methods: {
    toLater() {
      this.local_dialog = false
      this.$router.push({path: '/selectivitat'})
    },
    async confirmPassCode() {
      if (this.confirmPassTimes <= 0) this.resetPass = false;
      if (this.resetCodePassReal === this.resetCodePass) {
        this.errorMessageCodePass = false;
        await this.updatePassword();
      } else {
        this.errorMessageCodePass = true;
        this.confirmPassTimes--;
      }
    },
    async updatePassword() {
      try {
        await this.axios.post("/profile/password", {
          user_id: this.userId,
          old_password: 'PasswordContacte_17',
          new_password: this.loginForm.password
        })
        await this.onSignIn(this.loginForm.email, this.loginForm.password);
      } catch (err) {

      } finally {
        this.resetPass = false;
      }
    },
    async onSignIn(email, password) {
      this.loading = true
      try {

        const res = await this.axios.post('/login', {
          user_email: email,
          user_password: password
        })

        if (res.data.user) {
          this.$root.currentUser.value = res.data.user
          this.$root.token.value = res.data.token
          // this.$cookies.set("user", res.data.user)
          // this.$cookies.set("token", res.data.token)
          this.$router.push({path: this.prevRoutePath});
          this.login = true;
        }
      } catch (err) {
        if (err && err.response && err.response.status === 401) this.errorMessage = err.response.data.message
        else if (err && err.response && err.response.status === 500) {
          this.resetPass = true;

          this.resetCodePassReal = err.response.data.code;
          this.userId = err.response.data.userId;
        }
      } finally {
        this.loading = false
      }

    },
    async addAssignatura() {
      this.loading = true
      try {
        // await this.$store.commit('addAssignatura', {
        //   branca: this.registerForm.branca,
        //   assignatures: this.registerForm.assignatures,
        //   nickname: this.registerForm.nickname,
        //   institut: this.registerForm.institut
        // })
      } finally {
        this.local_dialog = false
        setTimeout(() => {
          this.$router.push({path: '/selectivitat'})
          this.loading = false
          this.registerForm = {
            name: '',
            email: '',
            password1: '',
            password2: '',
            branca: '',
            assignatures: [],
            nickname: '',
            institut: ''
          }
        }, 700)
      }
    },
    async onRegister() {
      this.loading = true
      const user = {
        user_name: this.registerForm.name,
        user_email: this.registerForm.email,
        user_password: this.registerForm.password1
      }

      try {
        const res = await this.axios.post('/register', user);
        await this.onSignIn(this.registerForm.email, this.registerForm.password1);
      } catch (err) {

        if (err.response.status === 401) this.errorMessage = err.response.data.message
      } finally {
        this.loading = false;
      }
    },
    passVisibility() {
      this.value = !this.value
      if (!this.value2) this.value2 = true
    },
    passVisibility2() {
      this.value2 = !this.value2
      if (!this.value) this.value = true
    },
    close(name) {
      const array = this.registerForm.assignatures
      const index = array.indexOf(name)
      if (index >= 0) array.splice(index, 1)
    },
    async afterLog() {
      await this.$router.push({path: '/'})
    }
  },
  watch: {
    'registerForm.password1'(val) {
      if (val && this.registerForm.email) {
        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.registerForm.email)) && val !== '') {
          this.invalidEmail = true
        } else {
          this.invalidEmail = false
        }
      }
    },
    local_dialog(val) {
      if (!val) this.loading = false
    },
    getUser(user) {
      console.log("user", user)
      if (user) {
        this.afterLog()
      }
    }

  }
}
</script>

<style>
.center {
  width: 50%;
  margin: auto;
}

.v-btn--outlined {
  border: 2px solid currentColor !important;
  border-top-color: currentcolor;
  border-top-style: solid;
  border-top-width: 2px !important;
  border-right-color: currentcolor;
  border-right-style: solid;
  border-bottom-color: currentcolor;
  border-bottom-style: solid;
  border-left-color: currentcolor;
  border-left-style: solid;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-repeat: initial;
}

.v-text-field--outlined fieldset {
  border-collapse: collapse;
  border-color: currentColor;
  border-style: solid;
  border-width: 2px !important;
}

.tit {
  color: #333333;
  font-weight: 600;
  font-family: Rubik, sans-serif !important;
}

/*.v-messages {*/
/*  display: none !important;*/
/*  min-height: 0 !important;*/
/*  padding: 0 !important;*/
/*}*/

/*.v-text-field__details {*/
/*  display: none !important;*/
/*}*/
</style>
