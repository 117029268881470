<template>
  <div>

    <!-------------------------------------->
    <!-- APP BAR                          -->
    <!-------------------------------------->

    <v-app-bar
      color="white"
      app
      height="73"
      max-height="73"
      style="z-index: 100 !important; box-shadow: #0000001f 0px 3px 6px 0px !important;"
      class="pa-0 align-center"
      :class="$vuetify.breakpoint.xs ? '' : 'px-3'"
      flat
      clipped-left
    >


      <router-link
        to="/" class="d-flex align-center">
        <v-avatar color="transparent" size="42">
          <v-img src="../../../assets/logo.png"
                 width="42"
                 height="42"
                 contain
          ></v-img>
        </v-avatar>
      </router-link>

      <router-link
        to="/dossiers-selectivitat" class="d-flex align-center">
        <v-toolbar-title
          v-if="!$vuetify.breakpoint.xs"
          style="cursor:pointer;" class="ml-3 mt-1 mr-5">
          <h1
            style="color: #1872e1; font-size: 1.2em; letter-spacing: -1px !important; font-weight: 700 !important"
            class="mb-1 font-rubik">
            <span>examenselectivitat</span>
          </h1>
        </v-toolbar-title>
      </router-link>



      <v-hover v-slot:default="{ hover }">
        <v-btn
          @click="$router.push({path: '/?assignatures=true'})"
          text :ripple="false"
          :color="hover ? '#256EFF' : 'black'"
          style="letter-spacing: normal; width: 147px"
          class="text-none font-weight-medium font-rubik no-background-hover"
          aria-label="Exàmens"
        >
          Exàmens
          <v-icon
            :color="hover ? '#256EFF' : 'black'"
          >{{ mdiChevronDown() }}
          </v-icon>
        </v-btn>
      </v-hover>

      <v-hover v-slot:default="{ hover }">
        <v-btn
          @click="$router.push({path: '/dossiers-selectivitat'})"
          text :ripple="false"
          :color="hover ? '#256EFF' : 'black'"
          style="letter-spacing: normal"
          aria-label="Dossiers"
          class="text-none no-background-hover mr-5 dossiers-btn"
        >
          <v-badge color="#FFA201" dot>
          <span class="font-weight-medium font-rubik">
          Dossiers
          </span>
          </v-badge>
        </v-btn>
      </v-hover>

      <v-spacer style="flex: 1 !important;"></v-spacer>


      <v-card
        v-if="i"
        height="36"
        color="transparent" flat
        class="d-flex align-center justify-end text-end pl-3 rounded-0" :ripple="false">
      <span
        @click="i.checkoutDrawer=true"
        class="font-weight-medium font-rubik rounded-lg"
        style="font-size: 15px; letter-spacing: normal; cursor: pointer"
      >
        Cistella
      </span>
        <v-btn
          @click="i.checkoutDrawer=true"
          x-large class="no-background-hover rounded-0 pr-1"
          :ripple="false"
          aria-label="Cistella"
          icon>
          <v-badge
            :content="cart"
            :value="cart"
            color="primary"
            overlap
            class="rounded-0"
          >
            <v-icon color="black" size="25">{{ mdiShoppingOutline() }}</v-icon>
          </v-badge>
        </v-btn>
      </v-card>
    </v-app-bar>


    <!-------------------------------------->
    <!-- CHECKOUT NAVIGATION              -->
    <!-------------------------------------->
    <checkout-nav
      v-if="i"
      :value.sync="i.checkoutDrawer"
    ></checkout-nav>


  </div>
</template>

<script>
import {mdiChevronDown, mdiShoppingOutline} from "/src/assets/mdi.json";
import Shop from "../Shop";
import CheckoutNav from "../cart/CartNav.vue";

export default {
  name: "ShopToolBar",
  components: {CheckoutNav},
  data() {
    return {
      i: null,
    }
  },
  mounted() {
    this.i = Shop.Instance
  },
  computed: {
    cart() {
      return this.$root.cart
    },
    list() {
      const list = JSON.parse(this.$cookies.get("cart"));
      return list ? list : [];
    }
  },
  methods: {
    mdiChevronDown() {
      return mdiChevronDown
    },
    mdiShoppingOutline() {
      return mdiShoppingOutline
    }
  },
  watch: {
    cart(val) {
      if (val) {
        this.i.checkoutDrawer = true
      }
    }
  }
}
</script>

<style scoped>

</style>
