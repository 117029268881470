import cookieClass from "./cookieClass";
import axios from 'axios';

export default class userCookie extends cookieClass {

  private admin = false;

  public userId = null;

  public premium = false;

  constructor(name: string) {
    super(name)
  }

  public async setValues(user) {
    if (user) {
      if (this.value)
        this.userId = this.value.user_id
      await this.setAdmin()
    }
  }

  public isAdmin() {
    return this.admin;
  }

  public isEditor() {
    return this.value && this.value.role && this.value.role === 'editorX';
  }

  public async setAdmin() {
    if (this.value) {
      const {data} = await axios.get("/admin/" + this.value.user_id);
      this.admin = data;
    }
  }


}
