<template>
	<div class="pt-3 d-flex align-center">
  <span class="font-rubik white--text font-weight-medium"
		style="font-size: 14px"
  >
    Assignatures populars:
  </span>
		<v-chip-group class="ml-2">
			<router-link
				v-for="assignatura in popular"
				:to="'/selectivitat/' + assignatura.subject_name"
				style="width: 100%"
				:aria-label="assignatura.subject_name"
			>
				<v-chip
					color="#5890FF"
					class="white--text font-weight-medium font-rubik"
				>
					{{ assignatura.subject_name }}
				</v-chip>
			</router-link>
		</v-chip-group>
	</div>
</template>

<script>
export default {
	data() {
		return {
			popular: {}
		}
	},
	async mounted() {
		await this.getPopular()
	},
	methods: {
		async getPopular() {
			const {data} = await this.axios.get("/trends/subjects");
			this.popular = data
			if (this.$vuetify.breakpoint.xs) {
				this.popular = this.popular.slice(0, 2)
			}
		}
	}
}
</script>

<style scoped>

</style>
