<template>
  <div style="width: 100%; height: 100%; max-width: 1120px" class="pa-3 mx-auto">
    <h1 class="tit" :class="$vuetify.breakpoint.xs ? 'mt-4' : 'mt-12'">Registra't i gaudeix de privilegis</h1>
    <v-row class="align-start">
      <v-col cols="12" md="6" order-md="first" order="last">
        <p> Registra't per guardar les teves assignatures i gaudir d'exàmens personalitzats.</p>
        <v-img max-height="330px" contain class="mx-auto"
               src="https://examenselectivitat.cat:3000/api/images/other/examenselectivitat-iniciar-sessio.png"></v-img>
      </v-col>
      <v-col cols="12" md="6" offset-md="last" order="first" class="align-start">
        <login-card class="mt-3"></login-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="invalidEmail"
                top
    >
      El email és invàlid.
      <v-btn
        color="pink"
        text
        @click="invalidEmail = false"
      >
        Tancar
      </v-btn>
    </v-snackbar>

  </div>
</template>

<script>
import Loader from '../../../components/VLoader'
import controller from "@/store/Controller";
import LoginCard from "./components/LoginCard";

export default {
  components: {
    LoginCard,
    pulseLoader: Loader
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  mounted() {
    if (this.getUser) {
      this.afterLog()
    }
  },
  data() {
    return {
      prevRoute: null,
      controller: controller,
      value: true,
      value2: true,
      text: [
        'Iniciar sessió',
        'Crear nou compte'
      ],
      loginForm: {
        email: '',
        password: ''
      },
      registerForm: {
        name: '',
        email: '',
        password1: '',
        password2: '',
        branca: '',
        assignatures: [],
        nickname: '',
        institut: ''
      },
      errors: null,
      showErrors: false,
      local_dialog: null,
      invalidEmail: false,
      checkbox: false,
      google: false,
      login: false,
      addedAssig: false,
      instituts: null,
      loading: false,
      activated: false,
      errorMessage: null
    }
  },
  computed: {
    prevRoutePath() {
      return this.prevRoute ? this.prevRoute.path : '/'
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'md'
    },
    checkDoublePass: function () {
      return (this.registerForm.password1 === this.registerForm.password2) && this.registerForm.password2.length > 6
    },
    getInitial() {
      return this.registerForm.name.substring(0, 1)
    },
    getRandomColor() {
      return '#' + Math.floor(Math.random() * 16777215).toString(16)
    },
    assignatures() {
      return null
    },
    canRegister() {
      return this.checkDoublePass &&
        this.checkbox &&
        this.registerForm.name !== '' &&
        (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.registerForm.email))
    },
    somhi() {
      if (!this.registerForm.branca ||
        !this.registerForm.assignatures ||
        !this.registerForm.institut ||
        !this.registerForm.nickname ||
        this.registerForm.assignatures.length === 0 ||
        this.registerForm.assignatures === []) {
        return true
      } else return false
    },
    isUserInfo() {
      return (this.getUser.branca && this.getUser.assignatures && this.getUser.nickname && this.getUser.institut)
    }
  },

  methods: {

    toLater() {
      this.local_dialog = false
      this.$router.push({path: '/selectivitat'})
    },
    async onSignIn(email, password) {
      this.loading = true
      try {

        const res = await this.axios.post('/login', {
          user_email: email,
          user_password: password
        })

        if (res.data.user) {
          this.$root.currentUser.value = res.data.user
          this.$root.token.value = res.data.token
          // this.$cookies.set("user", res.data.user)
          // this.$cookies.set("token", res.data.token)
          this.$router.push({path: this.prevRoutePath});
          this.login = true;
        }
      } catch (err) {
        if (err && err.response && err.response.status === 401) this.errorMessage = err.response.data.message
      } finally {
        this.loading = false
      }

    },
    async addAssignatura() {
      this.loading = true
      try {
        // await this.$store.commit('addAssignatura', {
        //   branca: this.registerForm.branca,
        //   assignatures: this.registerForm.assignatures,
        //   nickname: this.registerForm.nickname,
        //   institut: this.registerForm.institut
        // })
      } finally {
        this.local_dialog = false
        setTimeout(() => {
          this.$router.push({path: '/selectivitat'})
          this.loading = false
          this.registerForm = {
            name: '',
            email: '',
            password1: '',
            password2: '',
            branca: '',
            assignatures: [],
            nickname: '',
            institut: ''
          }
        }, 700)
      }
    },
    async onRegister() {
      this.loading = true
      const user = {
        user_name: this.registerForm.name,
        user_email: this.registerForm.email,
        user_password: this.registerForm.password1
      }

      try {
        const res = await this.axios.post('/register', user);
        await this.onSignIn(this.registerForm.email, this.registerForm.password1);
      } catch (err) {

        if (err.response.status === 401) this.errorMessage = err.response.data.message
      } finally {
        this.loading = false;
      }
    },
    passVisibility() {
      this.value = !this.value
      if (!this.value2) this.value2 = true
    },
    passVisibility2() {
      this.value2 = !this.value2
      if (!this.value) this.value = true
    },
    close(name) {
      const array = this.registerForm.assignatures
      const index = array.indexOf(name)
      if (index >= 0) array.splice(index, 1)
    },
    // async afterLog() {
    //   this.loading = false
    //   this.google = false
    //   this.login = false
    //   if (this.$route.path === '/') await this.$router.push({path: '/perfil'})
    // }
  },
  watch: {
    'registerForm.password1'(val) {
      if (val && this.registerForm.email) {
        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.registerForm.email)) && val !== '') {
          this.invalidEmail = true
        } else {
          this.invalidEmail = false
        }
      }
    },
    local_dialog(val) {
      if (!val) this.loading = false
    },
    getUser(user) {
      if (user && (this.google || this.login)) {
        this.loading = false
        this.afterLog()
      }
    }

  }
}
</script>

<style>
.center {
  width: 50%;
  margin: auto;
}

.v-btn--outlined {
  border: 2px solid currentColor !important;
  border-top-color: currentcolor;
  border-top-style: solid;
  border-top-width: 2px !important;
  border-right-color: currentcolor;
  border-right-style: solid;
  border-bottom-color: currentcolor;
  border-bottom-style: solid;
  border-left-color: currentcolor;
  border-left-style: solid;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-repeat: initial;
}

.v-text-field--outlined fieldset {
  border-collapse: collapse;
  border-color: currentColor;
  border-style: solid;
  border-width: 2px !important;
}

.tit {
  color: #333333;
  font-weight: 600;
  font-family: Rubik, sans-serif !important;
}

/*.v-messages {*/
/*  display: none !important;*/
/*  min-height: 0 !important;*/
/*  padding: 0 !important;*/
/*}*/

/*.v-text-field__details {*/
/*  display: none !important;*/
/*}*/
</style>
