<template>
  <v-hover
    v-slot:default="{hover}">
    <v-btn
      @click="$router.push({path: '/checkout'})"
      height="42" style="max-height: 42px !important;"
      width="100%"
      :class="hover ? 'shadow-small' : 'shadow-sm'"
      depressed color="primary" class="mx-auto text-none font-weight-medium body-2 my-3 rounded-lg">
      Tramitar comanda
    </v-btn>
  </v-hover>
</template>

<script>
export default {
  name: "CheckoutButton",
}
</script>

<style scoped>

</style>
