<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    right
    temporary
    style="z-index: 130; overflow-y: visible"
    width="485"
    overlay-opacity="0.75"
  >
    <v-card width="100%" height="100%" tile
            style="overflow-y: visible"
            color="#F3F3F3"
            class="d-flex flex-column"
    >

      <!---------------- FREE SHIPPING BANNER ------------------>
      <v-card color="primary" tile height="38" flat min-height="38"
              class="text-center align-center d-flex flex-column">
        <span class="font-rubik white--text my-auto font-weight-medium"
              style="font-size: 11px"
        >
          Enviament gratuït per comandes superiors a 5 dossiers
        </span>

      </v-card>

      <v-card flat tile class="pt-8 px-4">

        <!------------------- CLOSE BUTTON ----------------------->
        <v-icon
          @click="drawer=false"
          style="position: absolute; right: 10px; top: 10px" size="20"
          color="black">{{ mdiClose() }}
        </v-icon>


        <!------------------- TITLE ----------------------->

        <span class="font-rubik font-weight-medium"
              style="font-size: 18px"
        >
          Cistella
        </span>

        <!-------------------  ITEMS ----------------------->
        <checkout-items v-if="cart > 0"
                        :items="items"
                        :allBooks="allBooks"
                        :shippings.sync="shippings"
                        :selectedShipment.sync="selectedShipment"
        ></checkout-items>


        <!------------------- CHECKOUT BUTTON ----------------------->
        <div
          v-if="cart > 0"
          class="mt-4">
          <checkout-button></checkout-button>
        </div>
      </v-card>


      <!------------------- SHIPPING INFO ----------------------->

      <v-card
        v-if="cart > 0"
        class="text-center px-12 py-4 mt-2" flat>
<!--        <p class="font-rubik mb-1" style="font-size: 13px !important; line-height: 1rem !important;">-->
<!--          El preu de-->
<!--          l'enviament es-->
<!--          calcularà-->
<!--          en el-->
<!--          moment de la-->
<!--          compra-->
<!--        </p>-->

<!--        <p class="font-rubik mb-1" style="font-size: 13px !important; line-height: 1rem !important;">-->
<!--         o-->
<!--        </p>-->
        <!---------------- FREE SHIPPING 2 ------------------>
<!--        <v-card color="white" tile height="38" flat-->
<!--                class="text-center align-center d-flex flex-column">-->
<!--    -->
<!--        </v-card>-->
        <span style="font-size: 13px">
        Pagament dut a terme amb Stripe. Pagament segur.
        </span>

        <img
          height="32" style="object-fit: contain"
          src="https://examenselectivitat.cat:3000/api/images/other/available-payments.webp">
      </v-card>


      <v-card
        v-if="cart > 0"
        style="flex: 1"
        color="white" class="mt-2" flat>

        <!------------------- SUGGESTED PRODUCTS ----------------------->

        <checkout-suggested
          :items="items"
          :allBooks="allBooks"
        ></checkout-suggested>



        <!------------------- CHECKOUT BUTTON 2 ----------------------->
        <div
          v-if="cart > 0"
          class="px-4 mt-4">
         <checkout-button></checkout-button>
        </div>
      </v-card>


      <v-card
        v-if="!cart" flat class="text-center pt-4"
        style="flex: 1"
      >
        <span class="font-rubik text-center"
              style="font-size: 12px"
        >La teva cistella està buida</span>
      </v-card>

    </v-card>
  </v-navigation-drawer>
</template>

<script>
import {mdiClose} from "/src/assets/mdi.json";
import CheckoutItems from "./components/CartItems.vue";
import CheckoutSuggested from "./components/CartSuggested.vue";
import CheckoutButton from "./components/CheckoutButton.vue";
import CartItems from "./components/CartItems.vue";

export default {
  name: "CheckoutNav",
  props: {
    value: Boolean
  },
  components: {CartItems, CheckoutButton, CheckoutSuggested, CheckoutItems},
  async mounted() {
    this.list = this.$cookies.get("cart");
    if (!this.allBooks) await this.fetchAllBooks();
    await this.updateBooksList();
  },
  data() {
    return {
      allBooks: null,
      drawer: this.value,
      items: [],
      shippings: [],
      list: null,
      selectedShipment: 0,
    }
  },
  computed: {
    cart() {
      return this.$root.cart
    },
  },
  methods: {
    mdiClose() {
      return mdiClose
    },
    async fetchAllBooks() {
      const {data} = await this.axios.get('/shop-list')
      this.allBooks = data;
    },
    async updateBooksList() {
      this.list = this.$cookies.get("cart")

      let books = []
      this.allBooks.forEach(d => books = books.concat([...d.subjects, ...d.apunts]));
      //
      this.items = [];
      books.forEach(b => {
        if (this.list[b.book_id]) {
          b.quantity = this.list[b.book_id];
          b.offers = [];
          b.offerPrices = {
            offer: 0,
            normal: b.quantity
          };

          for (let i = 0; i < b.quantity; i++) {
            b.offers.push(false);
          }
          this.items.push(b)
        }
      })

    },
    toDiscount() {
      let sum = 0;
      this.items.forEach(item => {
        sum += item.quantity;
      })
      return 5 - sum;
    },
  },
  watch: {
    drawer(val) {
      this.$emit("update:value", val);
    },
    value(val) {
      this.drawer = val;
    },
    '$root.cart'(val) {
      this.updateBooksList()
    },
    '$route.query': {
      immediate: true,
      async handler(val) {
        if (val.cart === 'true') {
          this.drawer = true;
        }
      }
    },
  }
}
</script>

<style scoped>
.slide-suggested >>> .v-slide-group__prev,
.slide-suggested >>> .v-slide-group__next {
  min-width: 26px !important;
}
</style>
