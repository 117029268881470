<template>
  <div>
    <v-dialog v-model="model" max-width="1000">
      <v-card class="pa-10">
        <div class="d-flex align-start">

          <div class="d-flex flex-column" style="flex:1;">
          <span style="font-size: 2rem"
                class="font-rubik font-weight-medium">Correccions dels exàmens del {{year}}</span>

          <span class="font-rubik">Les correccions són de la Generalitat de Catalunya i són provisionals, és a dir, poden contenir errades.</span>
          </div>
          <v-icon @click="model=false">{{ mdiClose() }}</v-icon>
        </div>
        <v-card v-for="subject in subjects"
                flat
                :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : ''"
                class="d-flex align-center pa-3 mb-5 rounded-lg shadow-small mt-4 px-5"
        >
          <v-card
            :style="`background: linear-gradient(to bottom,rgba(255,255,255,0.2),rgba(0,0,0,0.05)),${getColor(subject)};`"
            style="border-radius: 14px"
            width="64" height="64" class="d-flex flex-column align-center justify-center shadow-small" flat>
            <v-icon
              class="icon-color"
              color="white"
              style="opacity: 0.9"
              size="30">
              {{ $vuetify.icons.values[subject.subject_icon] }}

            </v-icon>
          </v-card>
          <span class="font-rubik ml-4" style="font-size: 1.2rem; flex: 1">{{ subject.subject_name }}</span>


          <v-btn
            @click="url(subject, 'Enunciat')"
            depressed color="#CCF3FF"
            class="text-none"
            :class="$vuetify.breakpoint.smAndDown ? 'mt-3' : ''"
            style="letter-spacing: unset; font-size: 1rem; color: #256EFF"
          >
            Mostrar enunciat
          </v-btn>
          <v-btn
            @click="url(subject, 'Solucio')"
            depressed color="grey lighten-2"
            aria-label="Mostrar solució"
            class="text-none "
            :class="$vuetify.breakpoint.smAndDown ? 'my-3 px-5' : 'ml-3'"
            style="letter-spacing: unset; font-size: 1rem"
          >
            Mostrar solució
          </v-btn>
        </v-card>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {mdiClose} from "/src/assets/mdi.json";

export default {
  name: "NewExams",
  props: {
    value: Boolean
  },
  async mounted() {


    // setTimeout(() => {
    //   this.model = true;
    // }, 2000)

    const {data} = await this.axios.get("/new-exams")
    this.subjects = data.filter(r => r.added);
  },
  data() {
    return {
      year: new Date().getFullYear(),
      model: false,
      subjects: [],
      colors: [
        "#FF8FB1",
        "#256EFF",
        "#FFA201",
        "#2FCA9F",
        "#C47AFF",
        "#40C4FF",
        "#A460ED",
        "#8C9EFF",
        "#2F89FC",
        "#00BFA5",
        "#ffa63c",
      ]
    }
  },
  methods: {
    mdiClose() {
      return mdiClose
    },
    getColor(subject) {
      return this.colors[subject.subject_id % this.colors.length]
    },
    async url(subject, type) {
      const d = new Date();
      let year = d.getFullYear();
      const monthNum = d.getMonth()
      const month = monthNum === 5 ? "Juny" : "Setembre"

      if (this.$vuetify.breakpoint.smAndDown) {
        const pdf = `https://examenselectivitat.cat:3000/api/pdfs/${subject.subject_path}/${year}/${month}/${type}`;
        window.open(pdf);
      } else {
        this.$router.push({path: '/selectivitat/' + subject.subject_name + `/pdf/${year}/${month}/` + type})
      }
    },
  },
  watch: {
    value(val) {
      this.model = val;
    },
    model(val) {
      this.$emit('update:value', val);
    }
  }
}
</script>

<style scoped>

</style>
