<template>
  <v-overlay
    v-if="$root.showCaptcha"
    z-index="200"
    :light="true"
    :opacity="0.8"
  >
    <vue-recaptcha
      ref="recaptcha"
      @verify="onCaptchaVerified"
      :sitekey="sitekey"
      style="z-index: 200 !important;"
    ></vue-recaptcha>
  </v-overlay>
</template>

<script>
import {VueRecaptcha} from 'vue-recaptcha';
// import vueRecaptcha from 'vue3-recaptcha2';

export default {
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      captchaResponse: null,
      sitekey: process.env.VUE_APP_CAPTCHA
    };
  },
  methods: {
    onCaptchaVerified(response) {
      this.$root.showCaptcha = false
      this.$forceUpdate()
    }
  }
}
</script>
