<template>
  <div class="my-12">
    <div class="justify-center text-center mb-6 d-flex flex-column">

      <a href="https://www.instagram.com/examenselectivitat/" target="_blank" aria-label="Instagram">
        <v-avatar size="150" style="border: thin solid #ececec; background-color: white" class="pa-4">
          <v-img
              src="../../../assets/logo.png"
              style="width: 60px !important;"
              contain
          ></v-img>
        </v-avatar>
      </a>

      <a href="https://www.instagram.com/examenselectivitat/" target="_blank" aria-label="Instagram">
        <span class="title" style="color: #15274D">@examenselectivitat</span>
      </a>


      <span class="font-rubik font-weight-bold mb-4 mt-10 px-2"
            style="font-size: 40px"
      >Segueix-nos a Instagram</span>
    </div>
    <div class="d-flex flex-wrap justify-center">
      <v-card
          v-for="post in posts"
          color="rgb(22 39 78)" flat
          class="ma-2"
          max-width="250"
          max-height="250"
          width="100%" height="100%">
        <v-img height="100%" width="100%"
               style="object-fit: fill !important;"
               :src="post.url"></v-img>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "InstagramFeed",
  data() {
    return {
      imageUrl: `https:/examenselectivitat.cat:3000/api/images/instagram/`,
      posts: [
        {
          url: 'https:/examenselectivitat.cat:3000/api/images/instagram/nt_medicina.jpg',
          alt: ""
        },
        {
          url: 'https://examenselectivitat.cat:3000/api/images/other/examenselectivitat-setup.png',
          alt: ""
        },
        {
          url: 'https:/examenselectivitat.cat:3000/api/images/instagram/t_fisica.jpg',
          alt: ""
        }
      ]
    }
  }
}
</script>
