<template>
  <div class="pa-0" style="background-color: white">
    <div class=" rounded-lg"
         :class="$vuetify.breakpoint.xs ? '' : '' "
    >

      <v-card
        width="100%"
        style="background: linear-gradient(96.95deg, #004FEC 3.1%, #003CB4 100%)"
        tile flat
        height="610"
      >
        <div style="max-width: 1280px"
             :style="$vuetify.breakpoint.xs ? 'padding: 3.2rem 1rem;' : 'padding: 3.2rem 2.5rem;'"
             class="mx-auto"
        >
          <h1 class="d-flex flex-column font-rubik"
              style="color: white; font-weight: 700 !important; line-height: 1.15em"
              :style="$vuetify.breakpoint.xs ? 'font-size: 24px !important' : ($vuetify.breakpoint.sm ? 'font-size: 36px !important' : 'font-size: 3.375rem !important')"
          >
            <span>Sigues el millor estudiant</span>
            <div class="d-flex align-end">
              <span>amb els exàmens</span>
              <typewriter
                style="color: #FFA201"
                :replace="typewriter"
                :type-interval="50"
                :replace-interval="3000"
                :class="$vuetify.breakpoint.xs ? 'ml-2' : 'ml-3'"
              >
                <div style="letter-spacing: 0.2px !important;">per temes</div>
              </typewriter>

            </div>
          </h1>

          <div style="max-width: 850px">


            <p style="line-height: 1.75rem; color: white"
               :style="$vuetify.breakpoint.xs ? 'font-size: 0.9rem !important' : 'font-size: 1.25rem !important'"
               class="font-rubik mt-3 font-weight-medium">
              Milers de problemes que t'ajudaran a millorar les teves qualificacions <br>i portar el teu estudi al
              següent
              nivell.
            </p>

            <search-bar-v2 class="mt-8"></search-bar-v2>

            <popular-exams></popular-exams>



            <div
              style="width: 96px; height: 96px"
              class="arrow-landing mx-auto">
              <svg id="content" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 88" fill="none">
                <path fill="#FFA200"
                      d="m44 43 .7071.7071-.7071.7071-.7071-.7071L44 43Zm22.7071-21.2929-22 22-1.4142-1.4142 22-22 1.4142 1.4142Zm-23.4142 22-22-22 1.4142-1.4142 22 22-1.4142 1.4142Z"></path>
              </svg>
            </div>


          </div>

          <img
            v-if="$vuetify.breakpoint.mdAndUp"
            src="https://examenselectivitat.cat:3000/api/images/other/HomePreview.webp"
            class="homePreview"
            alt="Vista previa examens selectvitat"
            :style="`right: ${getPreviewRight}px`"
          >


        </div>
      </v-card>


      <div
        id="assignatures"
        ref="assignatures"
        style="border-bottom: 1px solid #E3E9ED"
      >
        <AssignaturesSelector
          style="margin-top: -124px"
        ></AssignaturesSelector>
      </div>
    </div>


    <!---------------------------------------->
    <!-- Repassa en qualsevol lloc i moment -->
    <!---------------------------------------->
    <div
      class="d-flex flex-column mx-auto mt-12"
      style="padding: 5rem 2.5rem; max-width: 1280px"
    >


      <h2 class="mb-3 font-weight-medium font-rubik"
          style="font-size: 40px; color: black"
      >Repassa en qualsevol lloc i moment</h2>
      <span style="font-size: 18px; color: rgba(103,103,103, 1); font-weight: 500"
            class="font-rubik"
      >
        Accés il·limitat a exàmens, notes de tall, guies d'estudi i més...
      </span>
      <v-row class="justify-start align-start mt-4">
        <v-col
          v-for="(card, idx) in appsCards"
          cols="12"
          sm="6"
          md="6"
          lg="3"
          :class="idx > 0 && !$vuetify.breakpoint.mdAndDown ? 'pl-1' : ''"
          class="justify-start text-start align-start"
        >
          <home-apps :card="card" class="my-3"
          ></home-apps>
        </v-col>
      </v-row>


      <div class="my-12 py-12 d-flex justify-center">
        <v-hover v-slot:default="{ hover }">
          <v-btn
            @click="$router.push({path: '/login'})"
            class="px-12 text-none font-weight-bold font-rubik rounded-lg white--text"
            style="font-size: 16px; letter-spacing: normal; box-shadow: 0px 2px 4px rgba(188,198,212,0.14),0px 3px 4px rgba(188,198,212,0.12),0px 1px 5px rgba(188,198,212,0.2);"
            depressed
            aria-label="Registra't gratis"
            width="100%"
            max-width="400"
            height="52"
            :color="hover ? '#FFA201' : 'primary'"
          >
            Registra't gratis
          </v-btn>
        </v-hover>
      </div>
    </div>


    <!------------------------------>
    <!-- TIRADA DE ROLLO          -->
    <!------------------------------>
    <v-card tile flat
            min-height="250" color="#740AFF"
            style="padding: 5rem 2.5rem;"
            class="text-center d-flex justify-center flex-column">
      <span style="font-size: 40px"
            class="white--text font-rubik font-weight-bold mb-6"
      >
        La nostra comunitat
      </span>
      <v-row
        style="max-width: 1280px; width: 100%"
        no-gutters class="mx-auto mt-6">
        <v-col v-for="(stat, idx) in stats" cols="12"
               v-if="stat"
               md="4"
               class="text-center justify-center reveal"
               :style="'transition-delay: '+ idx*200 + 'ms'"
        >
          <div :class="$vuetify.breakpoint.smAndDown ? 'd-flex' : ''" class="align-center mx-auto white--text">
            <div class="d-flex items-center justify-center mx-auto">
                <span style="font-size: 50px; font-weight: 500;" class="font-rubik"
                >
                  {{ stat.real }}
                </span>
              <span style="font-size: 50px; font-weight: 500;" class="font-rubik">+</span>
            </div>

          </div>
          <span class="white--text font-rubik font-weight-medium"
                :class="$vuetify.breakpoint.smAndDown ? 'ml-2' : ''"
          >{{ stat.text }}</span>
        </v-col>
      </v-row>

    </v-card>


    <!------------------------------>
    <!-- EXPLICACIÓ               -->
    <!------------------------------>

    <div
      class="mt-12"
      :class="$vuetify.breakpoint.xs ? 'text-start' : 'text-center'"
      style="padding: 5rem 2.5rem">
      <h3
        class="font-weight-bold font-rubik mx-auto"
        :style="$vuetify.breakpoint.xs ? 'font-size: 34px !important' : 'font-size: 40px !important;'"
      >
        Per què examenselectivitat?
      </h3>
    </div>

    <v-row style="width: 100%; max-width: 1280px; background-color: white;"
           class="mx-auto mb-12"
           :class="$vuetify.breakpoint.xs ? 'pa-8' : 'pa-12'"
           v-for="(exp, idx) in explanations"
    >
      <v-col cols="12"
             sm="12"
             md="6"
             :order="(idx % 2 === 0 || $vuetify.breakpoint.xs) ? 2 : 1"
             class="align-center justify-center text-center">
        <v-img
          contain
          :width="$vuetify.breakpoint.xs ? '100%' : ''"
          class="ma-auto rounded-xl mt-6 mx-12"
          :src="exp.image"></v-img>
      </v-col>
      <v-col cols="12"
             sm="12"
             md="6"
             :order="(idx % 2 === 0 || $vuetify.breakpoint.xs) ? 1 : 2"
      >
        <div style="max-width: 400px" class="mx-auto">
          <h3
            class="font-weight-bold font-rubik"
            :style="$vuetify.breakpoint.xs ? 'font-size: 28px !important' : 'font-size: 32px !important;'"
          >
            {{ exp.title }}
          </h3>

          <p :class="$vuetify.breakpoint.xs ? 'mt-3 mb-8' : 'my-10'"
             style="font-size: 20px !important; color: #676767">
            {{ exp.desc }}
          </p>

          <v-hover v-slot:default="{ hover }">
            <v-btn
              @click="$router.push({path: exp.path})"
              class="px-12 text-none font-weight-bold font-rubik rounded-lg white--text"
              style="font-size: 16px; letter-spacing: normal; box-shadow: 0px 2px 4px rgba(188,198,212,0.14),0px 3px 4px rgba(188,198,212,0.12),0px 1px 5px rgba(188,198,212,0.2);"
              depressed
              width="100%"
              :aria-label="exp.action"
              max-width="400"
              height="52"
              :color="hover ? exp.color : 'primary'"
            >
              {{ exp.action }}
            </v-btn>
          </v-hover>
        </div>
      </v-col>


    </v-row>


    <!------------------------------>
    <!-- INSTAGRAM                -->
    <!------------------------------>

    <v-sheet color="#f0f6ff" class="py-12">
      <div style="max-width: 1200px"
           class="justify-center mx-auto pb-12">
        <instagram-feed></instagram-feed>
      </div>
    </v-sheet>


    <!--    <adsense-->
    <!--      v-if="$root.currentUser === null || !$root.currentUser.premium"-->
    <!--      slotter="6844584728"-->
    <!--      style="display:none !important;width:300px;height:250px"-->
    <!--    ></adsense>-->

  </div>
</template>
<script>
import {mdiSchoolOutline} from '/src/assets/mdi.json'
import {mdiFileDocumentOutline} from '/src/assets/mdi.json'
import {mdiNotebookOutline} from '/src/assets/mdi.json'
import {mdiCalculatorVariantOutline} from '/src/assets/mdi.json'

import Typewriter from "typewriter-vue";
import SearchBarV2 from "../cercador/SearchBarV2";
import HomeApps from "./components/HomeApps";
import InstagramFeed from "./components/InstagramFeed";
// import Assignatures from "./components/Assignatures";
import AssignaturesSelector from "./components/AssignaturesSelector2";
// import Adsense from "../../components/anuncis/Adsense";
import PopularExams from "./components/PopularExams.vue";


export default {
  components: {
    PopularExams,
    // Adsense,
    AssignaturesSelector,
    // Assignatures,
    Typewriter,
    InstagramFeed,
    HomeApps,
    SearchBarV2
  },
  async mounted() {


    setInterval(() => {
      this.typewriter.push({from: "per temes", to: "resolts"})
      this.typewriter.push({from: "resolts", to: "oficials"})
      this.typewriter.push({from: "oficials", to: "per temes"})
    }, 9000)

    function tiradaDeRollo() {
      var reveals = document.querySelectorAll(".reveal");

      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 50;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    }

    window.addEventListener("scroll", tiradaDeRollo);


    // If path is "/selectivitat" open subjects dialog


  },
  data() {
    return {
      mdiSchoolOutline: mdiSchoolOutline,
      mdiFileDocumentOutline: mdiFileDocumentOutline,
      mdiNotebookOutline: mdiNotebookOutline,
      mdiCalculatorVariantOutline: mdiCalculatorVariantOutline,
      explanations: [
        {
          title: "Estudia sense perdre el temps",
          desc: "No perdis temps buscant les solucions dels problemes en un altre document. Aquí trobaràs totes les solucions dels exercicis en un sol clic.",
          image: "https://examenselectivitat.cat:3000/api/images/other/solucions.gif",
          action: "Començar",
          path: "/login",
          color: "#122143"
        },
        {
          title: "Dossiers de la Selectivitat 2025",
          desc: "Per aquells que els hi agrada tenir-ho tot en paper i boli, hem posat a la venda els nous dossiers de la Selectivitat pel curs 2024-2025.",
          image: "https://examenselectivitat.cat:3000/api/images/other/dossiers.gif",
          action: "Més informació",
          path: "/dossiers-selectivitat",
          color: "#2FCA9F"
        },
        {
          title: "La selectivitat classificada per temes",
          desc: "Estudiant per temes t'ajuda a estructurar millor la informació i per això s'obtenen millors resultats. Fes tants exercicis com puguis per convertir-te en un master!",
          image: "https://examenselectivitat.cat:3000/api/images/other/historia-temes.jpg",
          action: "Veure exemple",
          path: "/selectivitat/Història",
          color: "#FFA201"
        },
      ],
      temesPopulars: [],

      typewriter: [
        {from: "per temes", to: "resolts"},
        {from: "resolts", to: "classificats"},
        {from: "classificats", to: "oficials"},
        {from: "oficials", to: "per temes"},
      ],
      branchesLayout: [[1, 2, 3], [4, 5]],
      lastSelectedBranch: 0,
      branchesLeft: this.$refs.branches ? this.$refs['branches'].$el.offsetLeft : 0,
      selectedBranchTop: 0,
      stats: [
        {
          real: '600,000',
          start: 99746,
          incr: 276,
          text: "alumnes i professors"
        },
        {
          real: '30,000,000',
          start: 2999999,
          incr: 555,
          text: "visites"
        },
        {
          real: 600,
          start: 200,
          incr: 1,
          text: "instituts ens recomanen"
        }
      ],
      maxWidthContainer: 1200,
      exampleProblem: null,
      subjects: null,
      subject: 2,
      loading: false,
      trends_subjects: [],
      appsCards: [
        {
          title: 'Notes de tall',
          icon: mdiSchoolOutline,
          desc: "Recull de les notes de tall dels darrers anys.",
          href: '/notes-de-tall',
          color: '#BED4FF',
          colorFg: '#003CB4',
        },
        {
          title: 'Exàmens i solucions',
          icon: mdiFileDocumentOutline,
          desc: "Exàmens de la selectivitat resolts i classificats per temes.",
          href: '/selectivitat',
          color: '#99E7FF',
          colorFg: '#006D8F',
        },
        {
          title: 'Dossiers',
          icon: mdiNotebookOutline,
          desc: "Quaderns de problemes de la selectivitat.",
          href: '/dossiers-selectivitat',
          color: '#CBA3FF',
          colorFg: '#420099',
        },
        {
          title: 'Calculadora PAU',
          icon: mdiCalculatorVariantOutline,
          desc: "Eina per a calcular la nota d'd'accés a la universitat.",
          href: '/calculadora-selectivitat',
          color: '#FFDA99',
          colorFg: '#8F5B00',
        }
      ],
      activeTranslation: false,
      activeTranslationReverse: false,
      colors: [
        "#FF8FB1",
        "#256EFF",
        "#FFA201",
        "#2FCA9F",
        "#C47AFF",
        "#40C4FF",
        "#A460ED",
        "#8C9EFF",
        "#2F89FC",
        "#00BFA5",
        "#ffa63c",
      ]
    }
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
    },
    getPreviewRight() {
      let right;
      if (this.$vuetify.breakpoint.xs) {
        right = 0;
      } else if (this.$vuetify.breakpoint.sm) {
        right = 0;
      } else if (this.$vuetify.breakpoint.md) {
        right = -450;
      } else if (this.$vuetify.breakpoint.lg) {
        right = -190
      } else {
        right = 0;
      }

      return right
    }
  },
  methods: {
    scrollToAssignatures() {
      this.$refs["assignatures"].scrollIntoView({behavior: "smooth"})
    },
    getColor(subject) {
      return this.colors[subject.subject_id % this.colors.length]
    },
    getPath(subject_name) {
      return `/selectivitat/${subject_name}`;
    },
    deApostrof(assig) {
      const lletra = assig.charAt(0).toLowerCase()
      return lletra === 'a' ||
      lletra === 'e' ||
      lletra === 'i' ||
      lletra === 'o' ||
      lletra === 'u' ||
      lletra === 'h'
        ? "d'" + assig
        : 'de ' + assig
    }
  }
}
</script>
<style scoped>


@keyframes arrowLanding {
  0% {
    transform: translateY(0px)
  }

  50% {
    transform: translateY(30px)
  }

  100% {
    transform: translateY(0px)
  }
}

@keyframes arrowLandingPath {
  0% {
    stroke-width: 0
  }

  25% {
    stroke-width: 2px
  }

  50% {
    stroke-width: 4px
  }

  75% {
    stroke-width: 2px
  }

  100% {
    stroke-width: 0
  }
}


.homePreview {
  position: absolute;
  top: 0;
  object-fit: contain;
  max-width: 650px;
  z-index: 2;
}

.reveal {
  position: relative;
  transform: translateY(50px);
  opacity: 0;
  transition: 0.5s all ease;
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
}

.three-dots {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 160px;
  white-space: nowrap;
}

.small-title {
  font-weight: 500;
  text-transform: uppercase;
  font-size: .75rem;
  letter-spacing: .0625rem;
  line-height: 1.333333333333333;
  white-space: nowrap;
}

.text-images >>> img {
  display: block;
  max-width: 500px;
  margin: auto;
}

.selector-arrow {
  transition-property: all;
  transition-duration: 250ms;
}

.subjects-slide {
  -webkit-animation: slide-in-right 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-right 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.subjects-slide-reverse {
  -webkit-animation: slide-in-left 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-left 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(60px);
    transform: translateX(60px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(60px);
    transform: translateX(60px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}


/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-60px);
    transform: translateX(-60px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-60px);
    transform: translateX(-60px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}


/**
 * ----------------------------------------
 * Arrow landing
 * ----------------------------------------
 */

.arrow-landing {
  animation-name: arrowLanding;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  transition-timing-function: linear;
}


.arrow-landing svg {
  display: inline
}

.arrow-landing path {
  animation-name: arrowLandingPath;
  animation-duration: 3s;
  stroke: #ffa200;
  animation-iteration-count: infinite;
  transition-timing-function: linear
}

@keyframes arrowLanding {
  0% {
    transform: translateY(0)
  }

  50% {
    transform: translateY(30px)
  }

  to {
    transform: translateY(0)
  }
}

@keyframes arrowLandingPath {
  0% {
    stroke-width: 0
  }

  25% {
    stroke-width: 2px
  }

  50% {
    stroke-width: 4px
  }

  75% {
    stroke-width: 2px
  }

  to {
    stroke-width: 0
  }
}

</style>
