<template>
  <div style="background-color: white">
    <div
      style="max-width: 1280px; width: 100%; padding-left: 2.5rem; padding-right: 2.5rem"
      class="d-flex flex-column align-center justify-center mx-auto my-12">
      <div class="text-start align-start d-flex justify-start"
           style="width: 100%; height: 60px"
      >
        <h2>Explora per assignatures</h2>
      </div>

      <v-divider></v-divider>

      <div
        v-if="branches && branches.m_subjectsBranches"
        ref="branches"
        class="d-flex flex-wrap justify-center"
        style="background-color: transparent !important; width: 100%"
      >
        <v-col
          v-for="(branch, i) in branches.m_subjectsBranches"
          :key="i"
          class="mb-12 px-0"
          cols="12"
          sm="6"
          md="4"
          lg="4"
          style="height: fit-content !important;"
        >

          <div :style="$vuetify.breakpoint.xs ? 'margin-left: calc(50vw - 150px)' : ''">
            <v-chip
              v-if="branch.subjects && branch.subjects.length"
              style="font-size: 1.1em !important; line-height: 1.3 !important; height: 40px"
              class="font-weight-bold font-weight-medium rounded px-5 mb-2 font-rubik"
              :style="`color: ${branchesColors[i].fg}`"
              :color="branchesColors[i].bg"
            >
              <v-icon left>
                {{ $vuetify.icons.values[branches.m_subjectsBranches[i].subjects[1].subject_icon] }}

              </v-icon>
              {{ branch.branch_name }}
            </v-chip>
          </div>

          <div v-if="branches && branches.m_subjectsBranches[i]"
               style="max-width: 200px"
               class="align-start justify-start mt-2 d-flex flex-column"
               :class="$vuetify.breakpoint.xs ? 'mx-auto' : ''"
          >
            <router-link
              v-for="(subject, n) in branches.m_subjectsBranches[i].subjects"
              :aria-label="subject.subject_name"
              :key="n"
              class="mb-2"
              :class="$vuetify.breakpoint.xs ? 'mt-2 mb-4' : ''"
              :to="'/selectivitat/' + subject.subject_name"
            >
              <v-hover v-slot:default="{ hover }">
                      <span
                        @click="$emit('close')"
                        style="font-size: 1rem; line-height: 1.5rem; white-space: normal; font-weight: 500 !important;"
                        :style="hover ? 'color: black' : 'color: #4B5563'"
                        class="ml-2 font-rubik">
                                  {{ subject.subject_name }}
                                </span>
              </v-hover>
            </router-link>
          </div>


          <div
            v-else
            class="d-flex mt-10"
          >
            <div
              v-for="sk in 4"
              class="d-flex flex-column mx-10"
            >
              <v-skeleton-loader
                width="68px"
                height="68px"
                type="image"
                class="rounded-xl"
              ></v-skeleton-loader>
              <v-skeleton-loader
                type="text" class="mt-3"
              ></v-skeleton-loader>

            </div>
          </div>


        </v-col>
      </div>

    </div>
  </div>

</template>

<script>
import Branches from "../../../views/examens/Branches";


export default {
  name: "Assignatures",
  async mounted() {
    this.branches = await Branches.build();
  },
  data() {
    return {
      branches: [],
      branchesColors: [
        {
          bg: '#CCF3FF',
          fg: '#256EFF'
        },
        {
          bg: '#EAFBF4',
          fg: '#1B8F5D'
        },
        {
          bg: '#FFFAF0',
          fg: '#C05621'
        },
        {
          bg: '#E8D6FF',
          fg: '#4338CA'
        },
        {
          bg: '#FEE2E2',
          fg: '#B91D1C'
        },
        {
          bg: '#CCF3FF',
          fg: '#006D8F'
        },
      ],
    }
  }
}
</script>

<style scoped>

</style>
