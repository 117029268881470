<template>
  <div style="position: relative">
    <v-card
      flat
      color="rgb(246 246 246)"
      class="ma-0 rounded-0 d-flex flex-column align-start rounded-b-lg"

    >


      <div v-if="branches"
           class="align-start text-start d-flex flex-column mx-auto"
           style="width: 100%; max-width: 1280px"
           :style="$vuetify.breakpoint.xs ? 'padding: 3.2rem 1rem;' : 'padding: 3.2rem 2.5rem;'"
      >


        <!-------------------------------------->
        <!-- HORARI                           -->
        <!-------------------------------------->
        <horari :value.sync="horariDialog"></horari>

        <v-card
          v-if="false"
          class="d-flex flex-column text-center justify-center align-center pa-3 py-10 rounded-lg shadow-sm"
          style="max-width: 700px; width: 100%"
          flat
        >

          <v-btn
            @click="showNewExams=true"
            depressed color="primary"
            class="text-none rounded-pill font-rubik px-12 py-6 mb-8"
            aria-label="Correccions d'avui"
            style="letter-spacing: unset; font-size: 1rem;"
          >
            <v-icon class="mr-3" color="white">{{ mdiCheck }}</v-icon>
            Correccions dels exàmens del {{ year }}
          </v-btn>


          <v-badge content="Dimarts 4, dimecres 5 i dijous 6 de juny"
                   color="primary"
                   class="font-rubik font-weight-medium mx-auto"
                   offset-x="240"
                   offset-y="10"
          >
            <v-chip
              @click="horariDialog=!horariDialog"
              class="font-rubik font-weight-medium pl-6 pr-8 mx-auto"
              large
              outlined
            >
              <v-icon class="mr-3" color="black">{{ mdiCalendar }}</v-icon>
              Veure horari de la Selectivitat {{ year }}
            </v-chip>
          </v-badge>

          <new-exams
            :value.sync="showNewExams"></new-exams>
        </v-card>

        <div
          v-for="(branch, idxB) in branches.m_subjectsBranches"
          :key="idxB + 'assignatures-branch'"
          :class="activeTranslation ? 'subjects-slide' : (activeTranslationReverse ? 'subjects-slide-reverse' : '')"
          style="background-color: transparent !important; width: 100%"
          :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 700px' : 'max-width: 100%'"
          class="mt-10"
        >
          <span class="title font-weight-bold">{{ branch.branch_name }}</span>
          <div class="mt-3 d-flex flex-column">
            <div
              v-for="(subject, n) in branch.subjects"
              :key="`branch.subjects-${n}`"
              style="width: 100%"
            >
              <router-link :to="'/selectivitat/' + subject.subject_name"
                           style="width: 100%"
                           :aria-label="subject.subject_name"
              >
                <div class="mb-3 text-start" style="width: 100%">
                  <v-hover v-slot:default="{ hover }">
                    <v-sheet
                      class="rounded-lg text-start justify-start align-center d-flex pa-3"
                      style="width: 100%; min-width: 100%"
                      flat outlined
                      :style="(hover ? `transform: scale(1.02); ` : 'transform: scale(1); ')"
                    >
                      <v-card
                        width="40" height="40"
                        class="d-flex flex-column justify-center align-center mr-3 rounded-circle"
                        :style="`background: linear-gradient(to bottom,rgba(255,255,255,0.7),rgba(255,255,255,0.7)),${getColor(subject)}; `"
                        flat>
                        <v-icon
                          :color="getColor(subject)"
                          style="filter: brightness(80%);"
                          size="22">
                          {{ $vuetify.icons.values[subject.subject_icon] }}

                        </v-icon>
                      </v-card>
                      <span style="white-space: normal; line-height: 1 !important; color: #434343 !important"
                            class="font-rubik font-weight-medium blue-grey--text text--darken-4">
                      {{ subject.subject_name }}
                    </span>
                    </v-sheet>
                  </v-hover>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        style="width: 100%;max-width: 1280px "
        class="d-flex flex-column mb-3 mx-auto"
        :style="$vuetify.breakpoint.xs ? 'padding: 3.2rem 1rem;' : 'padding: 3.2rem 2.5rem;'"
      >
        <div
          v-for="deck in 4"
          :key="`deck-${deck}`"
          style="width: 100%; max-width: 700px"
          class="d-flex flex-column mb-3 mt-7"
        >
          <v-skeleton-loader
            type="text"
            class="mt-3 py-2 rounded-lg"
            style="max-width: 100px"
          ></v-skeleton-loader>

          <v-row
            v-for="sk in 4"
            :key="`skeleton-assig-${sk}`"
            no-gutters
            class="d-flex flex-column"
            style="width: 100%; max-width: 700px"
          >
            <v-skeleton-loader
              type="list-item-avatar" class="mt-3 py-2 rounded-lg white"
              style="border: thin solid #E0E0E0 !important;"
            ></v-skeleton-loader>

          </v-row>
        </div>
      </div>

    </v-card>
  </div>

</template>

<script>
import Branches from "../../examens/Branches";
import Horari from "../../../components/appbar/Horari.vue";
import {mdiCalendar, mdiCheck} from "/src/assets/mdi.json";
import NewExams from "./NewExams.vue";

export default {
  name: "AssignaturesSelector",
  components: {Horari, NewExams},
  async mounted() {
    this.branches = await Branches.build();
    this.subjectsBranches = this.branches.getSubjectsBranches()
  },
  data() {
    return {
      year: new Date().getFullYear(),
      mdiCalendar: mdiCalendar,
      horariDialog: false,
      showNewExams: false,
      mdiCheck: mdiCheck,
      branches: null,
      selectedBranchTop: 0,
      subjectsBranches: null,
      activeTranslation: false,
      activeTranslationReverse: false,
      selectedBranch: 0,
      branchesLeft: this.$refs.branches ? this.$refs['branches'].$el.offsetLeft : 38,
      branchesColors: [
        {
          bg: '#CCF3FF',
          fg: '#256EFF'
        },
        {
          bg: '#FEE2E2',
          fg: '#B91D1C'
        },
        {
          bg: '#FFFAF0',
          fg: '#C05621'
        },
        {
          bg: '#EAFBF4',
          fg: '#1B8F5D'
        },
        {
          bg: '#E8D6FF',
          fg: '#4338CA'
        },
        {
          bg: '#CCF3FF',
          fg: '#006D8F'
        },
      ],
      colors: [
        "#FF8FB1",
        "#256EFF",
        "#FFA201",
        "#2FCA9F",
        "#C47AFF",
        "#40C4FF",
        "#A460ED",
        "#8C9EFF",
        "#2F89FC",
        "#00BFA5",
        "#ffa63c",
      ]
    }
  },
  methods: {
    getColor(subject) {
      return this.colors[subject.subject_id % this.colors.length]
    },
    selectBranch(i) {
      const id = 'branch_' + i;
      this.branchesLeft = this.$refs['branches'].$el.offsetLeft - 20;
      this.selectedBranchTop = this.$refs[id][0].$el.offsetLeft;
      const selectedBranchWidth = this.$refs[id][0].$el.clientWidth;
      this.branchesLeft += (selectedBranchWidth / 2)

      if (this.lastSelectedBranch !== this.selectedBranch) this.lastSelectedBranch = this.selectedBranch
      this.selectedBranch = i
      if (this.lastSelectedBranch < this.selectedBranch) {
        this.activeTranslation = true;
        setTimeout(() => {
          this.activeTranslation = false;
        }, 300)
      } else {
        this.activeTranslationReverse = true;
        setTimeout(() => {
          this.activeTranslationReverse = false;
        }, 300)
      }
    },
  }

}
</script>

<style scoped>

.branch >>> .v-skeleton-loader__image {
  height: 35px !important;
}

.icon-color {
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.reveal {
  position: relative;
  transform: translateY(50px);
  opacity: 0;
  transition: 0.5s all ease;
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
}

.three-dots {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 160px;
  white-space: nowrap;
}


.selector-arrow {
  transition-property: all;
  transition-duration: 250ms;
}

.subjects-slide {
  -webkit-animation: slide-in-right 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-right 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.subjects-slide-reverse {
  -webkit-animation: slide-in-left 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-left 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(60px);
    transform: translateX(60px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(60px);
    transform: translateX(60px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}


/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-60px);
    transform: translateX(-60px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-60px);
    transform: translateX(-60px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}


</style>
