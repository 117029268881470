<template>
  <router-link :to="card.href">
    <v-card class="shadow-app text-start py-5 px-6 d-flex flex-column"
            flat
            max-width="350"
            style="border-radius: 14px !important; margin: auto"
    >
      <v-avatar :color="card.color" size="40">
        <v-icon
          size="25"
          :style="`color: ${card.colorFg}`">{{ card.icon }}
        </v-icon>
      </v-avatar>
      <span class="justify-start font-rubik pa-0 mt-3 font-weight-medium"
            style="font-size: 24px !important;"
      >
      {{ card.title }}
    </span>
      <p class="align-start px-0 mt-1 mb-0 font-rubik text-base"
      >
        {{ card.desc }}
      </p>
    </v-card>
  </router-link>
</template>

<script>
export default {
  name: "HomeApps",
  props: {
    card: Object
  }
}

</script>

<style scoped>
.shadow-app {
  box-shadow: 0px 2px 4px rgba(188, 198, 212, 0.14), 0px 4px 5px rgba(188, 198, 212, 0.12), 0px 1px 10px rgba(188, 198, 212, 0.2) !important;
}

.text-base {
  font-size: 16px !important;
  color: #676767;
  line-height: 1.5rem;
}

</style>
