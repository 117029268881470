export class AuthClass {

  private m_loginStatus: boolean = true;
  private m_loginStep: number = 1;

  public openLoginDialog() {
    this.m_loginStatus = true;
  }

  public get loginStatus() {
    return this.m_loginStatus;
  }

  public set loginStatus(val) {
    this.m_loginStatus = val;
  }

  public get step() {
    return this.m_loginStep;
  }

  public set step(val) {
    this.m_loginStep = val;
  }

  public closeLoginDialog() {
    this.m_loginStatus = false;
  }

  public toggleStatus() {
    this.m_loginStatus = !this.m_loginStatus;
  }

  public incrementStep() {
    this.m_loginStep++;
  }

  public decreaseStep() {
    if (this.m_loginStep > 0)
      this.m_loginStep--;
  }
}
