import {AuthClass} from "./AuthClass";

class Controller {
  private m_authClass: AuthClass;

  constructor() {
    this.m_authClass = new AuthClass();
  }

  get authClass() {
    return this.m_authClass;
  }
}

const controller = new Controller();
export default controller;
