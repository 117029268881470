<template>
  <ins
      v-if="$root.currentUser === null || !$root.currentUser.premium"
      class="adsbygoogle"
       :style="adStyle"
       :data-ad-client="adClient"
       :data-ad-format="adFormat"
       :data-ad-slot="adSlot"
       data-full-width-responsive="true"
       style="z-index: 99"
  ></ins>
</template>

<script>
export default {

  props: {
    adStyle: {
      type: String,
      required: false,
      default: "display:block",
    },
    adClient: {
      type: String,
      required: false,
      default: "ca-pub-8602467256798312",
    },
    adFormat: {
      type: String,
      required: false,
      default: "auto",
    },
    adSlot: {
      type: String,
      required: false,
      default: "8168816861",
    },
  },
  mounted() {

    if (this.$root.currentUser === null || !this.$root.currentUser.premium) {
      (adsbygoogle = window.adsbygoogle || []).push({});
    }
  }
}
</script>
