Date.prototype.addDays = function (days: number) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}


export default class Shop {

  // -----------------------------------
  // Instance
  // -----------------------------------

  private static _instance: Shop;

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }


  // -----------------------------------
  // Instance
  // -----------------------------------

  public checkoutDrawer = false;


  public nextWorkingDay(): Date {
    let date = new Date();
    date = date.addDays(1);

    let acc = 0;

    while ((this._isWeekend(date) || this._isHoliday(date)) && acc < 50) {
      date = date.addDays(1);

      acc++;
    }

    return date
  }

  // -----------------------------------
  // Festive days
  // -----------------------------------


  public arrivesAt(daysOfShipping): String {
    let arrivalDate = new Date();
    if (arrivalDate.getHours() >= 12) {
      arrivalDate = arrivalDate.addDays(1);
    }


    let acc = 0;

    while (acc < daysOfShipping) {
      // if (daysOfShipping === 3)
      //   console.log(acc, "<", daysOfShipping, arrivalDate.toLocaleDateString('ca-ES', {
      //     weekday: 'long',
      //     year: 'numeric',
      //     month: 'long',
      //     day: 'numeric'
      //   }).slice(0, -8), arrivalDate.getDay(), this._isWeekend(arrivalDate) || this._isHoliday(arrivalDate))

      arrivalDate = arrivalDate.addDays(1);

      if (!this._isWeekend(arrivalDate) && !this._isHoliday(arrivalDate))
        acc++;


    }

    const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};

    return arrivalDate.toLocaleDateString('ca-ES', options).slice(0, -8)
  }

  private _isWeekend(date) {
    return date.getDay() === 0 || date.getDay() === 6
  }

  private _isHoliday(date) {
    // console.log("this._cataloniaFestiveDays", this._cataloniaFestiveDays)
    // console.log("this._aragonFestiveDays", this._aragonFestiveDays)
    // console.log("this._spainFestiveDays", this._spainFestiveDays)
    const dateKey = date.getDate() + "/" + (date.getMonth() + 1)
    return this._spainFestiveDays.includes(dateKey) || this._cataloniaFestiveDays.includes(dateKey) || this._aragonFestiveDays.includes(dateKey)
  }

  private _spainFestiveDays = [
    "1/1", // Any nou
    "6/1", // Reis
    this._viernesSanto(),
    this._dillunsDePasqua(),
    "1/5", // Dia del treball
    "15/8", // l’Assumpció / Sant Maria
    "12/10", // Festa Nacional d'Espanya
    "1/11", // Tot Sants
    "6/12", // Dia de la Constitució
    "8/12", // La Immaculada
    "25/12", // Nadal
  ]

  private _aragonFestiveDays = [
    this._juevesSanto(), // Jueves santo
    "23/4" // Sant Jordi,
  ]

  private _cataloniaFestiveDays = [
    this._dillunsDePasqua(),
    this._pasquaGranada(),
    "24/6", // Sant Joan
    "11/9", // Diada Nacional de Catalunya
    "26/12", // Sant Esteve
  ]


  private _firstMonday() {
    return this._nextMonday(1, 1)
  }

  private _nextMonday(startingDay, month) {


    const actualYear = new Date().getFullYear();
    const dayOne = new Date(actualYear, month - 1, startingDay);
    let isMonday = false, fistMonday = null, i = 0;
    while (!isMonday && i < 7) {
      const day = dayOne.addDays(i);

      // Monday == 1
      if (day.getDay() === 1) {
        isMonday = true;
        fistMonday = day;
      }

      i++;
    }

    return fistMonday.getDate() + "/" + month
  }


  private _diumengeDePasqua() {
    const Y = new Date().getFullYear();
    let C = Math.floor(Y / 100);
    let N = Y - 19 * Math.floor(Y / 19);
    let K = Math.floor((C - 17) / 25);
    let I = C - Math.floor(C / 4) - Math.floor((C - K) / 3) + 19 * N + 15;
    I = I - 30 * Math.floor((I / 30));
    I = I - Math.floor(I / 28) * (1 - Math.floor(I / 28) * Math.floor(29 / (I + 1)) * Math.floor((21 - N) / 11));
    let J = Y + Math.floor(Y / 4) + I + 2 - C + Math.floor(C / 4);
    J = J - 7 * Math.floor(J / 7);
    let L = I - J;
    let M = 3 + Math.floor((L + 40) / 44);
    let D = L + 28 - 31 * Math.floor(M / 4);

    return new Date(Y, M, D);
  }

  private _juevesSanto() {
    const d = this._diumengeDePasqua().addDays(-3)

    return d.getDate() + "/" + d.getMonth()
  }

  private _viernesSanto() {
    const d = this._diumengeDePasqua().addDays(-2)

    return d.getDate() + "/" + d.getMonth()
  }

  private _dillunsDePasqua() {
    const d = this._diumengeDePasqua().addDays(1)

    return d.getDate() + "/" + d.getMonth()
  }

  private _pasquaGranada() {
    const d = this._diumengeDePasqua().addDays(50);
    return d.getDate() + "/" + d.getMonth()
  }

  private _sanJorge() {
    return this._nextMonday(23, 4)
  }

}
